import React, { useState } from 'react';
import { View, Text, Pressable, StyleSheet } from 'react-native';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { footerMenuItems } from './footerItems'; // Adjust the import path as needed

const Footer = () => {
  const [hoveredButton, setHoveredButton] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate

  return (
    <View style={styles.navbar}>
      <View style={styles.footerContent}>
        <Text style={styles.footerText}>© 2024 MyApp</Text>
        <View style={styles.footerMenu}>
          {footerMenuItems.map((item, index) => (
            <Pressable
              key={index}
              onPress={() => navigate(item.screen)} // Use navigate to navigate to the specified screen
              onMouseEnter={() => setHoveredButton(index)}
              onMouseLeave={() => setHoveredButton(null)}
              style={[
                styles.footerButton,
                hoveredButton === index && styles.buttonHover
              ]}
            >
              <Text style={styles.footerButtonText}>{item.label}</Text>
            </Pressable>
          ))}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  navbar: {
    width: '100%',
    backgroundColor: '#333',
    paddingVertical: 10,
    alignItems: 'center',
  },
  footerContent: {
    width: '100%',
    maxWidth: 800,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  footerText: {
    fontSize: 16,
    color: '#ffffff',
  },
  footerMenu: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  footerButton: {
    padding: 10,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#ccc',
    marginLeft: 10,
    backgroundColor: '#ffffff',
    transition: 'background-color 0.3s',
  },
  footerButtonText: {
    fontSize: 16,
    color: 'black',
  },
  buttonHover: {
    backgroundColor: '#e9ecef', // Bootstrap hover background color
  },
});

export default Footer;
