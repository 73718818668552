import { StyleSheet } from 'react-native';

const webStyles = {
  droppableContainer: {
    width: '100%',
    maxWidth: 400, // Bootstrap max width (25rem)
    minHeight: 400, // Bootstrap min height (25rem)
  },
  editPostButton: {
    paddingHorizontal: 10, // Bootstrap padding horizontal (0.625rem)
    marginLeft: 20, // Bootstrap margin left (1.25rem)
  },
  draggableItem: {
    userSelect: 'none',
    marginBottom: 8, // Bootstrap margin bottom (0.5rem)
    backgroundColor: '#fff',
    color: '#333',
    border: '1px solid #ccc',
    borderRadius: 5, // Bootstrap border radius (0.25rem)
    boxShadow: '0 1px 2px rgba(0,0,0,0.2)',
  },
  draggingItem: {
    backgroundColor: '#e0e0e0',
    boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
  },
  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 500, // Bootstrap max width (31.25rem)
    alignItems: 'center',
    backgroundColor: 'rgb(74, 144, 226)',
    color: '#fff',
    padding: 8, // Bootstrap padding (0.5rem)
    borderTopLeftRadius: 4, // Set top left border radius
    borderTopRightRadius: 4, // Set top right border radius
  },
  dragHandle: {
    cursor: 'grab',
  },
  taskName: {
    fontWeight: 'bold',
    color: '#fff',
  },
  taskNameEditing: {
    fontWeight: 'bold',
    color: '#000', // Changed color to black for edit mode
    backgroundColor: '#f0f8ff', // Highlighting edit text
  },
  taskDescription: {
    marginTop: 8, // Bootstrap margin top (0.5rem)
    color: '#333',
    paddingLeft:10,
    paddingRight:10,
    display: 'block',
    userSelect: 'text', // Allow text selection
 
  },
  viewDetails: {
    marginTop: 8, // Bootstrap margin top (0.5rem)
    color: '#fff',
    paddingHorizontal: 10,
    display: 'block',
    backgroundColor: 'rgb(74, 144, 226)',
  },
  actionIcons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 8, // Bootstrap margin top (0.5rem)
  },
  actionIcons2: {
    flexDirection: 'row',
    textAlign: 'center',
  },
  icon: {
    width: 30, // Bootstrap width (1.875rem)
    height: 30, // Bootstrap height (1.875rem)
    marginRight: 8, // Bootstrap margin right (0.5rem)
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: '#fff',
    padding: 20, // Bootstrap padding (1.25rem)
    borderRadius: 8, // Bootstrap border radius (0.5rem)
    textAlign: 'center',
  },
  modalActions: {
    marginTop: 20, // Bootstrap margin top (1.25rem)
    display: 'flex',
    justifyContent: 'space-around',
  },
  modalButton: {
    backgroundColor: 'rgb(74, 144, 226)',
    color: '#fff',
    padding: '10px 20px', // Bootstrap padding (0.625rem 1.25rem)
    border: 'none',
    borderRadius: 5, // Bootstrap border radius (0.3125rem)
    cursor: 'pointer',
  },
  input: {
    width: '100%',
    padding: 10, // Bootstrap padding (0.625rem)
    marginBottom: 10, // Bootstrap margin bottom (0.625rem)
    borderRadius: 4, // Bootstrap border radius (0.25rem)
    border: '1px solid #ccc',
    height: 50, // Bootstrap height (3.125rem)
    color: '#000', // added color
    fontSize: 16, // Set font size to 16 to prevent zooming
  },
  textArea: {
    width: '100%',
    padding: 20, // Bootstrap padding (1.25rem)
    marginBottom: 10, // Bootstrap margin bottom (0.625rem)
    borderRadius: 4, // Bootstrap border radius (0.25rem)
    border: '1px solid #ccc',
    resize: 'none',
    overflow: 'auto',
    color: '#000', // added color
    fontSize: 16, // Set font size to 16 to prevent zooming
  },
  addButton: {
    backgroundColor: 'rgb(74, 144, 226)',
    padding: 10, // Bootstrap padding (0.625rem)
    borderRadius: 5, // Bootstrap border radius (0.3125rem)
    textAlign: 'center',
    marginTop: 10, // Bootstrap margin top (0.625rem)
    color: '#fff',
    marginBottom:20,
  },
  addButtonText: {
    color: '#fff',
    fontWeight: 'bold',
    width: '100%',
    textAlign: 'center',
  },
  downArrow: {
    alignItems: 'center',
    display: 'flex',
    backgroundColor: '#d9d9d9',
    marginTop: 4,
    width: '100%',
  },
};

const mobileStyles = StyleSheet.create({
  droppableContainer: {

    
  },
  flexContainer: {
    flex: 1,
    width: '100%',
    alignItems: 'center',

  },
  list: {
    flex: 1,
    width: '100%',
    maxWidth: 400,
    flexGrow: 1,
  },
  entireItem: {
    flex: 1,
    flexGrow: 1,
    minHeight: 300,
    
  },
  downArrow: {
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#d9d9d9',
    marginTop: 6,
    padding: 2, // Ensure the down arrow has enough padding
    
  },
  editBox: {
    flex: 1,
    minHeight: 500,
    padding: 10,
    backgroundColor: '#fff',
    borderRadius: 8,
    marginBottom: 40,
  },
  contentContainer: {
    marginTop:15,
    paddingBottom: 25, // Ensure there's padding at the bottom of the list
    
  },
  item: {
    backgroundColor: '#fff',
    borderWidth: 2,
    borderColor: '#bbb',
    height: 120,
    borderRadius: 5,
    marginBottom: 20, // Add spacing between items
  },
  activeItem: {
    backgroundColor: '#e0e0e0',
  },
  headerRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'rgb(74, 144, 226)',
    padding: 8,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  taskName: {
    fontWeight: 'bold',
    color: '#fff',
    textAlign: 'center',
  },
  taskNameEditing: {
    fontWeight: 'bold',
    color: '#000',
    backgroundColor: '#f0f8ff',
  },
  taskDescription: {
    marginTop: 5,
    color: '#333',
    paddingHorizontal: 10,
    height: 36,
  },
  actionIcons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  actionIcons2: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10,
  },
  icon: {
    width: 30,
    height: 30,
    marginRight: 8,
  },
  modal: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 8,
    width: '90%',
    alignItems: 'center',
  },
  modalActions: {
    flexDirection: 'row',
    marginTop: 20,
  },
  modalButton: {
    marginHorizontal: 5,
    paddingVertical: 5,
    paddingHorizontal: 10,
    backgroundColor: 'rgb(74, 144, 226)',
    borderRadius: 5,
  },
  modalButtonText: {
    color: '#fff',
    fontWeight: 'bold',
  },
  input: {
    width: '100%',
    paddingLeft: 10,
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 10,
    marginLeft: 0,
    borderRadius: 4,
    borderWidth: 1,
    height: 'auto',
    borderColor: '#ccc',
    color: '#000', // added color
    fontSize: 16, // Set font size to 16 to prevent zooming
  },
  textArea: {
    minHeight: 10,
    textAlignVertical: 'top',
    paddingBottom: 0,
    color: '#000', // added color
  },
  editActions: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 0,
    paddingBottom: 0,
  },
  buttonRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  editableField: {
    backgroundColor: '#f0f8ff',
  },
  addButton: {
    backgroundColor: 'rgb(74, 144, 226)',
    padding: 10,
    color: '#fff',
    width: '100%',
  },
  addButtonText: {
    color: '#fff',
    fontWeight: 'bold',
    width: '100%',
    textAlign: 'center',
  },
  background: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
});

export { webStyles, mobileStyles };

