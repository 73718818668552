import { StyleSheet, Dimensions } from 'react-native';

const screenWidth = Dimensions.get('window').width;
const isMobile = screenWidth < 480;
import backgroundImage from '../assets/background.png';

export const loggedOutStyles = StyleSheet.create({
    
  scrollViewContainer: {
    flexGrow: 1,
    width: '100%',
    alignItems: 'center',
  },
  container: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
  },
  heroSection: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    padding: 15,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 10,
    shadowOffset: { width: 0, height: 5 },
    elevation: 5,
    marginBottom: 30,
    maxWidth:400,
  },
  textContainer: {
    flex: 1,
    alignItems: 'center',
    paddingRight: 20,
  },
  mainHeader: {
    fontSize: 28,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'left',
  },
  subHeader: {
    fontSize: 16,
    textAlign: 'left',
    color: '#555',
    marginBottom: 20,
  },
  joinButton: {
    backgroundColor: 'rgb(74, 144, 226)',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    marginBottom: 20,
  },
  joinButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  buttonRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 30,
  },
  startButton: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#4A90E2',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    marginHorizontal: 10,
  },
  buttonIcon: {
    width: 20,
    height: 20,
    marginRight: 10,
  },
  heroImage: {
    width: 200,
    height: 200,
    resizeMode: 'contain',
  },
  heroImageMobile: {
    maxWidth: '100%',
    height: 200,
    resizeMode: 'contain',
    marginBottom: 30,
  },
  section: {
    width: '100%',
    alignItems: 'center',
    marginBottom: 30,
    maxWidth: 400,
    backgroundColor: 'white',
    borderRadius: 10,
    paddingTop: 10,
  },
  sectionTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'center',
  },
  sectionDescription: {
    fontSize: 16,
    textAlign: 'left',
    color: '#555',
    paddingHorizontal: 20,
  },
  featureContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginBottom: 30,
  },
  featureBox: {
    width: '40%',
    backgroundColor: '#fff',
    padding: 15,
    margin: 10,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 10,
    shadowOffset: { width: 0, height: 5 },
    elevation: 5,
    alignItems: 'center',
  },
  featureIcon: {
    width: 30,
    height: 30,
    marginBottom: 10,
  },
  featureHeader: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#4A90E2',
    marginBottom: 5,
    textAlign: 'center',
  },
  featureDescription: {
    fontSize: 14,
    color: '#555',
    textAlign: 'left',
  },
});
export const webStyles = {
    background: {
      display: 'flex',
      justifyContent: 'center',
  
      width: '100%',
      minHeight: 'calc(100vh - 120px)', // Adjusted minHeight to account for footer
      backgroundColor: '#f8f9fa', // Bootstrap's light background color (fallback)
      paddingHorizontal: 5, // Add padding to create horizontal gap
      backgroundImage: `url(${backgroundImage})`, // Set the background image
      backgroundRepeat: 'repeat', // Repeat the image
      backgroundSize: 'auto', // Keep the original size of the image
    },
  };