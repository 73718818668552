import { StyleSheet, Platform } from 'react-native';
import backgroundImage from '../assets/background.png';

export const styles = StyleSheet.create({
    
  background: {
    flex: 1,
    width: '100%',


    alignItems: 'center',
  },
  text: {
    fontSize: 16,
    marginBottom: 16,
    color: '#000',
  },
  label: {
    fontSize: 16,
    marginBottom: 8,
    marginTop: 8,
    color: '#000',
    textAlign: 'center',
  },

  input: {
    height: 40,
    borderColor: '#ced4da',
    borderWidth: 1,
    marginBottom: 16,
    paddingVertical: 6,
    width: '100%',
    maxWidth: 370,
    alignSelf: 'center',
    color: '#000',
    fontSize: 16, // Set font size to 16 to prevent zooming
  },
  select: {
    height: 40,
    borderColor: '#ced4da',
    borderWidth: 1,
    marginBottom: 16,
    paddingHorizontal: 12,
    paddingVertical: 6,
    width: '100%',
    alignSelf: 'center',
    maxWidth: 370,
    color: '#000',
  },
  validationText: {
    marginBottom: 16,
    fontSize: 16,
    color: '#dc3545',
  },
  responseMessage: {
    marginTop: 16,
    fontSize: 16,
    color: 'rgb(74, 144, 226)',
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: '#ffffff',
    borderRadius: 4,
    padding: 20,
    width: 300,
    maxHeight: '80%',
  },
  saveButton: {
    maxWidth: 400,
    width:'100%',
    alignSelf: 'center',
  },
  modalItem: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#dee2e6',
  },
  headerBox: {
    borderWidth: 1,
    backgroundColor: '#fff',
    borderColor: '#ccc',
    borderRadius: 5,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 10,
    shadowOffset: { width: 0, height: 2 },
    marginBottom: 15,
    maxWidth: 400,
    width: '100%',
    alignSelf: 'center',
  },
});

export const webStyles = {
  background: {
    display: 'flex',
    justifyContent: 'center',

    width: '100%',
    minHeight: 'calc(100vh - 120px)', // Adjusted minHeight to account for footer
    backgroundColor: '#f8f9fa', // Bootstrap's light background color (fallback)
    paddingHorizontal: 5, // Add padding to create horizontal gap
    backgroundImage: `url(${backgroundImage})`, // Set the background image
    backgroundRepeat: 'repeat', // Repeat the image
    backgroundSize: 'auto', // Keep the original size of the image
  },
};
