import React, { useEffect, useState, useRef } from 'react';
import { View, Text, Button, TouchableOpacity, TextInput, Modal, Platform, Image } from 'react-native';
import { useParams, useNavigate } from 'react-router-dom'; // For web navigation
import { Helmet } from 'react-helmet'; // Import Helmet
import DraggableList from './DraggableList'; // Import the DraggableList component
import { commonStyles, webStyles } from '../styles/styles2'; // Adjust the import path as needed
import AsyncStorage from '@react-native-async-storage/async-storage'; // Import AsyncStorage for mobile

const HeaderDetailsPage = ({ headerId: propHeaderId, navigateTo, user: propUser }) => {
  const [headerDetails, setHeaderDetails] = useState({});
  const [taskList, setTaskList] = useState([]); // Initialize as empty array
  const [isEditingHeader, setIsEditingHeader] = useState(false);
  const [editedHeaderName, setEditedHeaderName] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [user, setUser] = useState(propUser);
  const [showActions, setShowActions] = useState(false);
  const { headerId: paramHeaderId } = useParams(); // Grab the headerId from the URL for web
  const navigate = Platform.OS === 'web' ? useNavigate() : null;
  const headerInputRef = useRef(null);

  useEffect(() => {
    if (isEditingHeader && headerInputRef.current) {
      headerInputRef.current.focus();
      if (Platform.OS !== 'web') {
        headerInputRef.current.setSelection(0, editedHeaderName.length);
      } else {
        headerInputRef.current.select();
      }
    }
  }, [isEditingHeader]);

  useEffect(() => {
    const fetchHeaderId = () => {
      if (Platform.OS === 'web') {
        return paramHeaderId;
      }
      return propHeaderId;
    };

    const id = fetchHeaderId();
    if (id) {
      fetchHeaderDetails(id);
    }

    const fetchUserData = async () => {
      try {
        let storedUserProfile;
        if (Platform.OS === 'web') {
          storedUserProfile = localStorage.getItem('userProfile');
        } else {
          storedUserProfile = await AsyncStorage.getItem('userProfile');
        }

        if (storedUserProfile) {
          setUser(JSON.parse(storedUserProfile));
        } else {
          console.log('No user profile found');
        }
      } catch (error) {
        console.error('Failed to retrieve user data:', error);
      }
    };

    fetchUserData();
  }, [propHeaderId, paramHeaderId]);

  const fetchHeaderDetails = async (id) => {
    try {
      const response = await fetch('https://taskpads.com/backend/taskdetails.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ Header_Id: id })
      });

      const data = await response.json();
      if (data.Tasks && data.Tasks.length > 0) {
        const sortedTasks = data.Tasks.sort((a, b) => a.Position - b.Position);
        setTaskList(sortedTasks);
      } else {
        setTaskList([]);
      }

      setHeaderDetails(data);
    } catch (error) {
      console.error('Failed to fetch header details:', error);
    }
  };

  const handleDragEnd = async (newOrder) => {
    const updatedTasks = newOrder.map((task, index) => ({
      ...task,
      Position: index // Update position based on new order
    }));

    setTaskList(updatedTasks);

    try {
      const response = await fetch('https://taskpads.com/backend/updateTaskOrder.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ tasks: updatedTasks })
      });

      const result = await response.json();
      if (result.status !== 'success') {
        console.error('Failed to update task order:', result.message);
      }
    } catch (error) {
      console.error('Error updating task order:', error);
    }
  };

  const handleBack = () => {
    if (Platform.OS === 'web') {
      navigate('/home');
    } else {
      navigateTo('Home');
    }
  };

  const handleNewTask = (newTask) => {
    setTaskList((prevTasks) => [...prevTasks, newTask]);
    fetchHeaderDetails(paramHeaderId || propHeaderId); // Re-fetch the header details to update the task list
  };

  const copyToClipboard = () => {
    if (Platform.OS === 'web') {
      navigator.clipboard.writeText(`http://taskpads.com/tasks/${paramHeaderId || propHeaderId}`);
    } else {
      Clipboard.setString(`http://taskpads.com/tasks/${paramHeaderId || propHeaderId}`);
    }
    alert('Link copied to clipboard');
  };

  const handleEditHeader = () => {
    setEditedHeaderName(headerDetails.Header_Name);
    setIsEditingHeader(true);
  };

  const handleSaveHeader = async () => {
    try {
      const response = await fetch('https://taskpads.com/backend/headerModification.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ Header_Id: paramHeaderId || propHeaderId, Header_Name: editedHeaderName, Action: 'edit' })
      });

      if (response.ok) {
        setHeaderDetails({ ...headerDetails, Header_Name: editedHeaderName });
        setIsEditingHeader(false);
      } else {
        console.error('Error updating header:', await response.json());
      }
    } catch (error) {
      console.error('Error updating header:', error);
    }
  };

  const handleDeleteHeader = async () => {
    try {
      const response = await fetch('https://taskpads.com/backend/headerModification.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ Header_Id: paramHeaderId || propHeaderId, Action: 'delete' })
      });

      if (response.ok) {
        handleBack();
      } else {
        console.error('Error deleting header:', await response.json());
      }
    } catch (error) {
      console.error('Error deleting header:', error);
    }
  };

  const renderContent = () => (
    <View style={commonStyles.container}>
      <View style={commonStyles.containerTop}>
        <View style={commonStyles.contentBox}>
          {isEditingHeader ? (
            <View style={commonStyles.headerEditContainer}>
              <TextInput
                ref={headerInputRef}
                style={commonStyles.headerEditInput}
                value={editedHeaderName}
                onChangeText={setEditedHeaderName}
                selectTextOnFocus={Platform.OS !== 'web'} // For mobile, select text on focus
              />
              <View style={commonStyles.editActions}>
                <Button title="Save" onPress={handleSaveHeader} />
                <Button title="Cancel" onPress={() => setIsEditingHeader(false)} />
              </View>
            </View>
          ) : (
            <View style={commonStyles.containerHeaderContainer}>
              <View style={commonStyles.headerContainer}>
                <TouchableOpacity onPress={handleBack} style={commonStyles.leftArrow}>
                  <Text style={commonStyles.leftArrowText}>◄</Text>
                </TouchableOpacity>
                <Text style={commonStyles.headerName}>{headerDetails.Header_Name}</Text>
                <View style={commonStyles.placeholder} />
              </View>
              <TouchableOpacity onPress={() => setShowActions(!showActions)} style={commonStyles.arrowButton}>
                <Text style={commonStyles.arrowButtonText}>{showActions ? '▲' : '▼'}</Text>
              </TouchableOpacity>
              {showActions && (
                <View style={commonStyles.actionIcons}>
                  <TouchableOpacity onPress={handleEditHeader}>
                    {Platform.OS === 'web' ? (
                      <img src="../assets/edit.png" alt="Edit" style={commonStyles.icon} />
                    ) : (
                      <Image source={require('../assets/edit.png')} style={commonStyles.icon} />
                    )}
                  </TouchableOpacity>
                  <TouchableOpacity onPress={copyToClipboard}>
                    {Platform.OS === 'web' ? (
                      <img src="../assets/linkcopy.png" alt="Copy Link" style={commonStyles.icon} />
                    ) : (
                      <Image source={require('../assets/linkcopy.png')} style={commonStyles.icon} />
                    )}
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => setModalVisible(true)}>
                    {Platform.OS === 'web' ? (
                      <img src="../assets/trash.png" alt="Delete" style={commonStyles.icon} />
                    ) : (
                      <Image source={require('../assets/trash.png')} style={commonStyles.icon} />
                    )}
                  </TouchableOpacity>
                </View>
              )}
            </View>
          )}
        </View>
      </View>
      <View style={commonStyles.containerBottom}>
        <DraggableList data={taskList} onDragEnd={handleDragEnd} navigateTo={navigateTo} headerId={paramHeaderId || propHeaderId} onNewTask={handleNewTask} />
      </View>

      <Modal
        transparent={true}
        visible={modalVisible}
        animationType="slide"
        onRequestClose={() => setModalVisible(false)}
      >
        <View style={commonStyles.modal}>
          <View style={commonStyles.modalContent}>
            <Text>Are you sure you want to delete this header and all its tasks?</Text>
            <View style={commonStyles.modalActions}>
              <Button title="Cancel" onPress={() => setModalVisible(false)} />
              <Button title="Delete" onPress={handleDeleteHeader} />
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );

  return (
    Platform.OS === 'web' ? (
      <>
        <Helmet>
          <title>{headerDetails.Header_Name ? `${headerDetails.Header_Name} - TaskPads` : 'TaskPads'}</title>
        </Helmet>
        <div style={webStyles.background}>
          {renderContent()}
        </div>
      </>
    ) : (
      <>
        {renderContent()}
      </>
    )
  );
};

export default HeaderDetailsPage;
