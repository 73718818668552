import React, { useState, useEffect } from 'react';
import { View, Image, Button, StyleSheet, Platform } from 'react-native';
import CustomButton from './components/CustomButton'; // Adjust the import path as needed

// Importing images for web platform
import head1 from '../assets/profile/heads/head1.png';
import head2 from '../assets/profile/heads/head2.png';
import head3 from '../assets/profile/heads/head3.png';
import head4 from '../assets/profile/heads/head4.png';

import eyes1 from '../assets/profile/eyes/eyes1.png';
import eyes2 from '../assets/profile/eyes/eyes2.png';
import eyes3 from '../assets/profile/eyes/eyes3.png';
import eyes4 from '../assets/profile/eyes/eyes4.png';

import mouth1 from '../assets/profile/mouths/mouth1.png';
import mouth2 from '../assets/profile/mouths/mouth2.png';
import mouth3 from '../assets/profile/mouths/mouth3.png';
import mouth4 from '../assets/profile/mouths/mouth4.png';

import nose1 from '../assets/profile/noses/nose1.png';
import nose2 from '../assets/profile/noses/nose2.png';
import nose3 from '../assets/profile/noses/nose3.png';
import nose4 from '../assets/profile/noses/nose4.png';

// Loading images for mobile platform using require
const heads = [
  require('../assets/profile/heads/head1.png'),
  require('../assets/profile/heads/head2.png'),
  require('../assets/profile/heads/head3.png'),
  require('../assets/profile/heads/head4.png'),
];

const eyes = [
  require('../assets/profile/eyes/eyes1.png'),
  require('../assets/profile/eyes/eyes2.png'),
  require('../assets/profile/eyes/eyes3.png'),
  require('../assets/profile/eyes/eyes4.png'),
];

const mouths = [
  require('../assets/profile/mouths/mouth1.png'),
  require('../assets/profile/mouths/mouth2.png'),
  require('../assets/profile/mouths/mouth3.png'),
  require('../assets/profile/mouths/mouth4.png'),
];

const noses = [
  require('../assets/profile/noses/nose1.png'),
  require('../assets/profile/noses/nose2.png'),
  require('../assets/profile/noses/nose3.png'),
  require('../assets/profile/noses/nose4.png'),
];

// Combine web and mobile images into arrays
const headsWeb = [head1, head2, head3, head4];
const eyesWeb = [eyes1, eyes2, eyes3, eyes4];
const mouthsWeb = [mouth1, mouth2, mouth3, mouth4];
const nosesWeb = [nose1, nose2, nose3, nose4];

const AvatarGenerator = ({ seed, setSeed }) => {
  const [headIndex, setHeadIndex] = useState(0);
  const [eyesIndex, setEyesIndex] = useState(0);
  const [mouthIndex, setMouthIndex] = useState(0);
  const [noseIndex, setNoseIndex] = useState(0);
  const [bgColor, setBgColor] = useState('#fff');

  useEffect(() => {
    if (seed) {
      const [head, eyes, mouth, nose, bg] = seed.split('-');
      setHeadIndex(parseInt(head));
      setEyesIndex(parseInt(eyes));
      setMouthIndex(parseInt(mouth));
      setNoseIndex(parseInt(nose));
      setBgColor(bg);
    } else {
      randomize();
    }
  }, [seed]);

  const randomize = () => {
    const newHeadIndex = Math.floor(Math.random() * heads.length);
    const newEyesIndex = Math.floor(Math.random() * eyes.length);
    const newMouthIndex = Math.floor(Math.random() * mouths.length);
    const newNoseIndex = Math.floor(Math.random() * noses.length);
    const newBgColor = generateRandomColor();

    setHeadIndex(newHeadIndex);
    setEyesIndex(newEyesIndex);
    setMouthIndex(newMouthIndex);
    setNoseIndex(newNoseIndex);
    setBgColor(newBgColor);

    const newSeed = `${newHeadIndex}-${newEyesIndex}-${newMouthIndex}-${newNoseIndex}-${newBgColor}`;
    setSeed(newSeed);
  };

  const generateRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const renderImage = (src) => {
    if (Platform.OS === 'web') {
      return <img src={src} alt="avatar part" style={styles.layer} />;
    }
    return <Image source={src} style={styles.layer} />;
  };

  return (
    <View style={styles.avatarContainer}>
      <View style={[styles.avatar, { backgroundColor: bgColor }]}>
        {Platform.OS === 'web'
          ? renderImage(headsWeb[headIndex])
          : renderImage(heads[headIndex])}
        {Platform.OS === 'web'
          ? renderImage(eyesWeb[eyesIndex])
          : renderImage(eyes[eyesIndex])}
        {Platform.OS === 'web'
          ? renderImage(mouthsWeb[mouthIndex])
          : renderImage(mouths[mouthIndex])}
        {Platform.OS === 'web'
          ? renderImage(nosesWeb[noseIndex])
          : renderImage(noses[noseIndex])}
      </View>
      <CustomButton onPress={randomize} style={styles.randomizeButton}>
        Randomize
      </CustomButton>
    </View>
  );
};

const styles = StyleSheet.create({
  avatarContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
  },
  avatar: {
    width: 100,
    height: 100,
    borderRadius: 50, // Makes the avatar a circle
    position: 'relative',
    overflow: 'hidden', // Ensures that the child images do not overflow the circular container
    alignItems: 'center',
    justifyContent: 'center',
    margin:5,
  },
  layer: {
    position: 'absolute',
    width: 75,
    height: 75,
    resizeMode: 'contain',
  },
  randomizeButton:{
    width:'100%',
  }
});

export default AvatarGenerator;
