import React, { useState, useEffect } from 'react';
import { Text, View, Pressable, Platform, Alert, Dimensions, TouchableOpacity, ScrollView, Modal, TouchableWithoutFeedback, Image } from 'react-native';
import { useAuth0 } from '../pages/Home'; // Assuming useAuth0 is exported from Home.js
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useAuth } from '../App'; // Adjust the import path as needed
import { useAuth0Login } from '../auth'; // Adjust the import path as needed
import styles from '../styles/navbarStyles'; // Import the styles from the new file
import { loggedInMenuItems, loggedOutMenuItems } from './menuItems';

let useNavigate;
if (Platform.OS === 'web') {
  useNavigate = require('react-router-dom').useNavigate;
}

const Navbar = ({ setCurrentScreen }) => {
  const { clearSession, logout } = useAuth0();
  const { user, setUser } = useAuth();
  const login = useAuth0Login(setUser);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(Dimensions.get('window').width < 500);
  const [hoveredButton, setHoveredButton] = useState(null);

  let navigate;
  if (Platform.OS === 'web') {
    navigate = useNavigate(); // Use the useNavigate hook
  }

  const handleNavigation = (screen) => {
    if (Platform.OS === 'web') {
      navigate(`/${screen.toLowerCase()}`);
    } else {
      setCurrentScreen(screen);
    }
  };

  const onLogout = async () => {
    try {
      if (Platform.OS === 'web') {
        localStorage.removeItem('userProfile');
        console.log('Removed user profile from localStorage');
        logout({ logoutParams: { returnTo: window.location.origin } });
      } else {
        console.log('Starting mobile logout...');
        await clearSession({});
        setUser(null);
        await AsyncStorage.removeItem('userProfile');
        await AsyncStorage.removeItem('userCredentials');
        setCurrentScreen('Home'); // Redirect to Home on logout
        Alert.alert('Logged out successfully');
      }
    } catch (e) {
      console.error('Logout Error:', e);
      Alert.alert('Logout Error', e.message);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(Dimensions.get('window').width < 500);
    };

    const subscription = Dimensions.addEventListener('change', handleResize);
    return () => subscription.remove();
  }, []);

  const loggedIn = user !== undefined && user !== null;

  return (
    <View style={styles.navbar}>
      <View style={styles.titleContainer}>
        {Platform.OS === 'web' ? (
          <img src="../assets/icon.png" alt="icon" style={styles.icon} />
        ) : (
          <Image source={require('../assets/icon.png')} style={styles.icon} />
        )}
        <Text style={styles.title}>Task Pads</Text>
      </View>
      {isSmallScreen ? (
        <View>
          <TouchableOpacity onPress={() => setIsMenuOpen(!isMenuOpen)} style={styles.menuButton}>
            <Text style={styles.menuButtonText}>☰</Text>
          </TouchableOpacity>
          <Modal
            visible={isMenuOpen}
            animationType="slide"
            transparent={true}
            onRequestClose={() => setIsMenuOpen(false)}
          >
            <TouchableWithoutFeedback onPress={() => setIsMenuOpen(false)}>
              <View style={styles.modalOverlay}>
                <TouchableWithoutFeedback>
                  <View style={styles.dropdownMenu}>
                    <ScrollView>
                      {loggedIn && loggedInMenuItems.map(({ label, screen }) => (
                        <Pressable
                          key={screen}
                          onPress={() => {
                            console.log(`Navigating to ${label}`);
                            handleNavigation(screen);
                            setIsMenuOpen(false);
                          }}
                          style={styles.dropdownItem}
                        >
                          <Text style={styles.dropdownButtonText}>{label}</Text>
                        </Pressable>
                      ))}
                      <Pressable
                        onPress={loggedIn ? () => { onLogout(); setIsMenuOpen(false); } : () => { login(); setIsMenuOpen(false); }}
                        style={styles.dropdownItem}
                      >
                        <Text style={styles.dropdownButtonText}>{loggedIn ? 'Log Out' : 'Log In'}</Text>
                      </Pressable>
                      {!loggedIn && loggedOutMenuItems.map(({ label, screen }) => (
                        <Pressable
                          key={screen}
                          onPress={() => {
                            console.log(`Navigating to ${label}`);
                            handleNavigation(screen);
                            setIsMenuOpen(false);
                          }}
                          style={styles.dropdownItem}
                        >
                          <Text style={styles.dropdownButtonText}>{label}</Text>
                        </Pressable>
                      ))}
                    </ScrollView>
                  </View>
                </TouchableWithoutFeedback>
              </View>
            </TouchableWithoutFeedback>
          </Modal>
        </View>
      ) : (
        <View style={styles.navItems}>
          {loggedIn && loggedInMenuItems.map(({ label, screen }) => (
            <Pressable
              key={screen}
              onPress={() => {
                console.log(`Navigating to ${label}`);
                handleNavigation(screen);
              }}
              onMouseEnter={() => setHoveredButton(screen)}
              onMouseLeave={() => setHoveredButton(null)}
              style={[
                styles.button,
                hoveredButton === screen && styles.buttonHover
              ]}
            >
              <Text style={styles.buttonText}>{label}</Text>
            </Pressable>
          ))}
          <Pressable
            onPress={loggedIn ? onLogout : login}
            onMouseEnter={() => setHoveredButton(loggedIn ? 'LogOut' : 'LogIn')}
            onMouseLeave={() => setHoveredButton(null)}
            style={[
              styles.button,
              hoveredButton === (loggedIn ? 'LogOut' : 'LogIn') && styles.buttonHover
            ]}
          >
            <Text style={styles.buttonText}>{loggedIn ? 'Log Out' : 'Log In'}</Text>
          </Pressable>
          {!loggedIn && loggedOutMenuItems.map(({ label, screen }) => (
            <Pressable
              key={screen}
              onPress={() => {
                console.log(`Navigating to ${label}`);
                handleNavigation(screen);
              }}
              onMouseEnter={() => setHoveredButton(screen)}
              onMouseLeave={() => setHoveredButton(null)}
              style={[
                styles.button,
                hoveredButton === screen && styles.buttonHover
              ]}
            >
              <Text style={styles.buttonText}>{label}</Text>
            </Pressable>
          ))}
        </View>
      )}
    </View>
  );
};

export default Navbar;