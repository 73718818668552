// screens.js
import Home from './pages/Home';
import Navbar from './navigation//Navbar';
import Profile from './pages/Profile';
import Login from './pages/Login';
import PrivacyPolicy from './pages/PrivacyPolicy';
import HeaderDetailsPage from './pages/HeaderDetailsPage';

export const routes = [
  { path: "/", component: Home },
  { path: "/home", component: Home },
  { path: "/profile", component: Profile },
  { path: "/tasks/:headerId", component: HeaderDetailsPage },
  { path: "/PrivacyPolicy", component: PrivacyPolicy }
];

export const screens = {
  Home,
  Profile,
  Login,
  PrivacyPolicy,
  HeaderDetailsPage
};

export {
  Navbar
};
