import { StyleSheet, Platform, Dimensions } from 'react-native';
import backgroundImage from '../assets/background.png';

const { width: screenWidth } = Dimensions.get('window');

export const commonStyles = StyleSheet.create({
  scrollContainer: {
    flexGrow: 1,
  },
  background: {
    flex: 1,
    height: '100%',
    alignItems: 'center',
  },
  containerHeaderContainer: {
    width: '100%',
  maxWidth:  Platform.OS === 'web' ? 400 : '100%',
  marginBottom:  Platform.OS === 'web' ? 10 : 0,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: Platform.OS === 'web' ? 400 : Math.min(screenWidth, 400),
    backgroundColor:'white',
    width:'100%',

  },
  headerNameContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  leftArrow: {
    borderRadius: 5,
    backgroundColor: 'rgb(74, 144, 226)',
  },
  leftArrowText: {
    fontSize: 25,
    paddingHorizontal: 5,
    color: '#fff',
    alignItems: 'center',
    paddingVertical: 5,
  },
  headerName: {
    flex: 1,
    textAlign: 'center',
    paddingLeft: Platform.OS === 'web' ? 0 : 25,
    paddingRight: 25,
    color: '#000',
     maxWidth:400,
width:'100%'
  },
  headerNameText:{     
    fontSize: 18,
  },
  placeholder: {
  
    width: 25,
  },
  arrowButton: {
    alignSelf: 'center',
    backgroundColor:'rgb(217, 217, 217)',
    textAlign: 'center',
    width:'100%',
    borderBottomLeftRadius:5,
    borderBottomRightRadius:5,
  },
  arrowButtonText: {

    textAlign: 'center',

  },
  headerButtonsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  backButton: {
    flex: 1,
    alignItems: 'flex-start',
    padding: 10,
    borderRadius: 5,
  },
  addTaskButton: {
    width: '100%',
    alignItems: 'center',
    padding: 10,
    borderRadius: 5,
  },
  buttonText: {
    color: '#fff',
    fontWeight: 'bold',
  },
  scrollViewContainer: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    
  },
  container: {
    flex: 1,
    alignItems: 'center',
    height: '100%',
  },
  containerTop: {
    alignItems: 'center',
    width: '100%',
  },
  containerBottom: {
    flex: 1,
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  contentBox: {
    borderRadius: 5,

    maxWidth:  Platform.OS === 'web' ? 400 : '100%',
    width: '100%',
    alignItems: 'center',
  },
  header: {
    fontSize: 18,
    marginBottom: 20,
    color: '#000',
  },
  text: {
    fontSize: 14,
    marginBottom: 10,
    color: '#000',
  },
  label: {
    fontSize: 16,
    marginBottom: 10,
    marginTop: 10,
    color: '#000',
  },
  input: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    marginBottom: 20,
    padding: 10,
    width: '100%',
    maxWidth: 250,
    color: '#000',
    fontSize: 16, // Set font size to 16 to prevent zooming
  },
  select: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    marginBottom: 20,
    padding: 10,
    width: '100%',
    maxWidth: 250,
    color: '#000',
  },
  picker: {
    height: 50,
    width: '100%',
    marginBottom: 20,
    maxWidth: 250,
    color: '#000',
  },
  validationText: {
    marginBottom: 20,
    fontSize: 16,
    color: 'red',
  },
  responseMessage: {
    marginTop: 20,
    fontSize: 16,
    color: 'blue',
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: 'white',
    borderRadius: 5,
    padding: 20,
    width: 300,
    maxHeight: '80%',
  },
  modalItem: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  submitButton: {
    backgroundColor: 'rgb(74, 144, 226)',
    padding: 10,
    borderRadius: 5,
  },
  backButton: {
    backgroundColor: 'rgb(74, 144, 226)',
    padding: 20,
    borderRadius: 5,
    marginTop: 20,
    marginBottom: 20,
  },
  submitButtonText: {
    color: '#fff',
    textAlign: 'center',
  },
  sortButton: {
    marginTop: 20,
    padding: 10,
    backgroundColor: 'rgb(74, 144, 226)',
    borderRadius: 5,
  },
  sortButtonText: {
    color: '#fff',
    textAlign: 'center',
  },
  dateHeader: {
    marginTop: 20,
    marginBottom: 10,
  },
  dateHeaderText: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#000',
  },
  headerName: {
    flex: 1,
    textAlign: 'center',
    color: '#000',
    fontSize: 16,
  },

  newTaskPadButton: {
    backgroundColor: 'rgb(74, 144, 226)',
    padding: 10,
    borderRadius: 5,
    marginBottom: 20,
  },
  newTaskPadButtonText: {
    color: '#fff',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  toggleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 10,
  },
  toggleLabel: {
    fontSize: 16,
    marginHorizontal: 10,
    color: '#000',
  },
  toggleSwitch: {
    transform: [{ scaleX: 1.2 }, { scaleY: 1.2 }],
  },
  icon: {
    width: 25,
    height: 25,
    marginLeft: 15,
    marginRight: 15,
  },
  headerEditContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  editActions:{
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width:'100%',
    margin:10,
    borderRadius: 5,
  },

  
  headerEditInput: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 4,
    padding: 10,
    width: '100%',
    textAlign: 'center',
    backgroundColor:'white',
    color: '#000',
    fontSize: 16, // Set font size to 16 to prevent zooming
  },
  clipboardLink: {
    fontSize: 16,
    color: 'rgb(74, 144, 226)',
    textAlign: 'center',
    marginVertical: 10,
    textDecorationLine: 'underline',
  },
  actionIcons: {
    flexDirection: 'row',
    justifyContent: 'space-between',

    width: '100%',
    backgroundColor: 'white',
    padding: 4,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,

  },
  modal: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalActions: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '80%',
    marginTop: 20,
  },
});

export const webStyles = {
  background: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    minHeight: 'calc(100vh - 120px)', // Adjusted minHeight to account for footer
    backgroundColor: '#f8f9fa', // Bootstrap's light background color (fallback)
    paddingHorizontal: 5, // Add padding to create horizontal gap
    backgroundImage: `url(${backgroundImage})`, // Set the background image
    backgroundRepeat: 'repeat', // Repeat the image
    backgroundSize: 'auto', // Keep the original size of the image
  },
};
