import { StyleSheet, Platform } from 'react-native';
import backgroundImage from '../assets/background.png';

export const commonStyles = StyleSheet.create({
  background: {
    flex: 1,
    width: '100%',
   
    backgroundColor: '#f8f9fa', // Bootstrap's light background color
  },
  scrollViewContainer: {
    flexGrow: 1,
    justifyContent: 'flex-start', // 'flex-start' instead of 'top'
 
  },
  scrollViewContent: {
    alignItems: 'center',
    width:'100%'
  },
  contentBox: {
    width: '100%',
    maxWidth: 400,
    backgroundColor: '#333',
    borderRadius: 5,
    paddingLeft: 10,
    paddingRight: 10,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 10,
    shadowOffset: { width: 0, height: 5 },
    borderColor: 'rgb(217, 217, 217)',
    marginBottom: 10,
    marginTop: 5,
  },
  headerListBox: {
    width: '100%',
    maxWidth: 400,
  },
  header: {
    fontSize: 20,
    width: '100%',
    marginBottom: 10,
    marginTop: 10,
    textAlign: 'center',
    color: '#fff', // added color
  },
  text: {
    fontSize: 16,
    marginBottom: 20,
    textAlign: 'center',
    color: '#000', // added color
  },
  newTaskPadButton: {
    backgroundColor: 'rgb(74, 144, 226)',
    padding: 10,
    borderRadius: 5,
width:'100%',
maxWidth:400,
  },
  newTaskPadButtonText: {
    color: '#fff',
    fontSize: 16,

    textAlign: 'center',
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContainer: {
    width: '90%',
    maxWidth: 400,
    backgroundColor: '#fff',
    borderRadius: 8,
    padding: 20,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 2,
    elevation: 5,
  },
  label: {
    fontSize: 16,
    marginBottom: 10,
    color: '#000', // added color
  },
  toggleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  toggleLabel: {
    fontSize: 16,
    color: '#000', // added color
  },
  toggleSwitch: {},
  input: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    padding: 10,
    marginBottom: 10,
    width: '100%',
    color: '#000', // added color
    fontSize: 16, // Set font size to 16 to prevent zooming
  },
  validationText: {
    color: 'red',
    marginBottom: 10,
  },
  modalButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  submitButton: {
    backgroundColor: 'rgb(74, 144, 226)',
    padding: 10,
    borderRadius: 5,
    width: '48%',
  },
  submitButtonText: {
    color: '#fff',
    textAlign: 'center',
  },
  cancelButton: {
    backgroundColor: '#ccc',
    padding: 10,
    borderRadius: 5,
    width: '48%',
  },
  cancelButtonText: {
    color: '#fff',
    textAlign: 'center',
  },
  sortButton: {
    backgroundColor: 'white',
    padding: 10,
    borderRadius: 5,
    marginTop: 10,
    marginBottom: 0,
  },
  sortButtonText: {
    color: '#fff',
    backgroundColor: 'rgb(74, 144, 226)',
    fontSize: 16,
    padding: 5,
    textAlign: 'center',
    borderRadius: 5,
  },
  headerContainer: {
    width: '100%',
    maxWidth: 400,
  },
  dateHeader: {
    alignItems: 'center',
    backgroundColor: '#333',
    marginTop: 10,
    borderRadius: 5,
    marginBottom: 5,
    padding: 5,
  },
  dateHeaderText: {
    fontSize: 16,
    color: '#fff', // added color
  },
  headerBox: {
    width: '100%',
    maxWidth: 400,
    backgroundColor: '#fff',
    borderColor: '#ccc',
    borderRadius: 5,
    padding: 10,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 10,
    shadowOffset: { width: 0, height: 2 },
    borderColor: 'rgb(217, 217, 217)',
    borderWidth: 2,
    marginBottom: 5,
  },
  headerNameText: {
    fontSize: 16,
    color: '#000', // added color
  },
  paginationContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: 20,
  },
  pageButton: {
    marginHorizontal: 5,
    padding: 10,
    backgroundColor: 'rgb(74, 144, 226)',
    borderRadius: 5,
  },
  pageButtonText: {
    color: '#fff',
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

export const webStyles = {
  background: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    minHeight: 'calc(100vh - 120px)', // Adjusted minHeight to account for footer
    backgroundColor: '#f8f9fa', // Bootstrap's light background color (fallback)
    paddingHorizontal: 5, // Add padding to create horizontal gap
    backgroundImage: `url(${backgroundImage})`, // Set the background image
    backgroundRepeat: 'repeat', // Repeat the image
    backgroundSize: 'auto', // Keep the original size of the image
  },
};
