// auth.js
import { Platform, Alert } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import { useAuth0 } from './pages/Home'; // Adjust the import path as needed
import config from './auth0-configuration'; // Ensure correct path

export const useAuth0Login = (setUser) => {
  const { loginWithRedirect, authorize, getIdTokenClaims } = useAuth0();

  const fetchUserProfile = async (accessToken) => {
    try {
      const response = await axios.get(`https://${config.domain}/userinfo`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      console.log('User profile fetched:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching user profile:', error);
      return null;
    }
  };
  const sendEmailToEndpoint = async (email) => {
    try {
      const response = await fetch('https://taskpads.com/backend/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();
      console.log('Server response:', data);
      return data;
    } catch (error) {
      console.error('Error sending email to endpoint:', error);
      return null;
    }
  };
  const login = async () => {
    try {
      if (Platform.OS === 'web') {
        await loginWithRedirect();
      } else {
        console.log('Starting mobile authorization...');
        const authResult = await authorize({ scope: 'openid profile email' });
        console.log('Authorization successful:', authResult);

        console.log('Fetching user...');
        const userProfile = await fetchUserProfile(authResult.accessToken);
        if (userProfile) {
          setUser(userProfile);
          await AsyncStorage.setItem('userProfile', JSON.stringify(userProfile));
          const credentials = { accessToken: authResult.accessToken };
          await AsyncStorage.setItem('userCredentials', JSON.stringify(credentials));
          Alert.alert('Login Successful', `Welcome, ${userProfile.name}`);
          
          // Send email to endpoint and update user profile with additional data
          const serverResponse = await sendEmailToEndpoint(userProfile.email);
          if (serverResponse) {
            userProfile.username = serverResponse.username;
            userProfile.timezone = serverResponse.timezone;
            await AsyncStorage.setItem('userProfile', JSON.stringify(userProfile));
            if (Platform.OS === 'web') {
              localStorage.setItem('userProfile', JSON.stringify(userProfile));
            }
          }
        } else {
          console.log('User profile fetch failed');
          Alert.alert('User profile fetch failed');
        }
      }
    } catch (e) {
      console.error('Login Error:', e);
      Alert.alert('Login Error', e.message);
    }
  };

  return login;
};




