// menuItems.js

export const loggedInMenuItems = [
  {
    label: 'Home',
    screen: 'Home',
  },
  {
    label: 'Profile',
    screen: 'Profile',
  }
];

export const loggedOutMenuItems = [

];
