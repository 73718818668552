import React from 'react';
import { AppRegistry, Platform } from 'react-native';
import App from './App';
import { name as appName } from './app.json';

// Register the main application component for Android and iOS
AppRegistry.registerComponent(appName, () => App);

if (Platform.OS === 'web') {
  const { createRoot } = require('react-dom/client');

  // Create a root element to render the app
  const rootTag = document.getElementById('root') || document.createElement('div');
  if (!rootTag.id) {
    rootTag.id = 'root';
    document.body.appendChild(rootTag);
  }

  // Register the application for web using the new createRoot API
  const root = createRoot(rootTag);
  root.render(<App />);
}
