import React from 'react';
import { View, Text, ScrollView, Image, Dimensions, Platform, ImageBackground } from 'react-native';
import { useAuth } from '../App'; // Adjust the import path as needed
import { useAuth0Login } from '../auth'; // Adjust the import path as needed
import { loggedOutStyles as styles, webStyles } from '../styles/loggedOutStyles'; // Adjust the import path as needed

import CustomButton from './components/CustomButton'; // Adjust the import path as needed

// Static imports for gallery images and icons
import iconReceipt from '../assets/icons/icon-receipt.png';
import iconCubeAlt from '../assets/icons/icon-cube-alt.png';
import iconImages from '../assets/icons/icon-images.png';
import iconShield from '../assets/icons/icon-shield.png';
import iconAtom from '../assets/icons/icon-atom.png';
import iconIdCard from '../assets/icons/icon-id-card.png';
import googleIcon from '../assets/icons/googleicon.png';
import appleIcon from '../assets/icons/appleicon.png';
import hero from '../assets/hero-img.png';

const LoggedOut = () => {
  const { setUser } = useAuth();
  const login = useAuth0Login(setUser);

  const screenWidth = Dimensions.get('window').width;
  const isMobile = screenWidth < 480;

  // Features array with icons
  const features = [
    { icon: iconReceipt, title: 'Create Recipes', description: 'Easily create and share recipes with others.' },
    { icon: iconCubeAlt, title: 'Manage Tasks', description: 'Organize your tasks efficiently and share them with your team.' },
    { icon: iconImages, title: 'Capture Notes', description: 'Quickly jot down notes and share them with friends or colleagues.' },
    { icon: iconShield, title: 'Secure Sharing', description: 'Share your data securely with end-to-end encryption.' },
    { icon: iconAtom, title: 'AI-Powered', description: 'Utilize AI for quick and smart task creation.' },
    { icon: iconIdCard, title: 'Personalized Experience', description: 'Get a personalized experience tailored to your needs.' },
  ];

  const renderContent = () => (
    <ScrollView contentContainerStyle={styles.scrollViewContainer}>
      <View style={styles.container}>
        <View style={styles.heroSection}>
          <View style={styles.textContainer}>
            <Text style={styles.mainHeader}>TaskPads</Text>
            <Text style={styles.subHeader}>
              TaskPads lets you effortlessly create recipes, tasks, and notes, all shareable with others. Experience AI-powered quick task creation to boost your productivity.
            </Text>
            <CustomButton onPress={login} style={styles.joinButton}>
              Join Free
            </CustomButton>
          </View>
          {!isMobile && <Image source={hero} style={styles.heroImage} />}
        </View>

        {!isMobile && (
          <View style={styles.buttonRow}>
            <CustomButton onPress={login} style={styles.startButton}>
              <Image source={googleIcon} style={styles.buttonIcon} />
              Google Play
            </CustomButton>
            <CustomButton onPress={login} style={styles.startButton}>
              <Image source={appleIcon} style={styles.buttonIcon} />
              App Store
            </CustomButton>
          </View>
        )}

        {isMobile && <Image source={hero} style={styles.heroImageMobile} />}

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>App Features</Text>
          <Text style={styles.sectionDescription}>
            Discover the powerful features of TaskPads. From creating and sharing recipes to managing tasks and notes, TaskPads is designed to enhance your productivity with AI-powered functionalities.
          </Text>

          <View style={styles.featureContainer}>
            {features.map((feature, index) => (
              <View key={index} style={styles.featureBox}>
                <Image source={feature.icon} style={styles.featureIcon} />
                <Text style={styles.featureHeader}>{feature.title}</Text>
                <Text style={styles.featureDescription}>{feature.description}</Text>
              </View>
            ))}
          </View>
        </View>
      </View>
    </ScrollView>
  );

  return (
    Platform.OS === 'web' ? (
      <div style={webStyles.background}>
        {renderContent()}
      </div>
    ) : (
      <ImageBackground source={require('../assets/background.png')} style={styles.background} resizeMode="repeat">
        {renderContent()}
      </ImageBackground>
    )
  );
};

export default LoggedOut;
