import React, { useState, useEffect } from 'react';
import { View, Text, Platform, TextInput, TouchableOpacity, ActivityIndicator, ScrollView, Modal, Switch, ImageBackground } from 'react-native';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for web navigation
import { commonStyles, webStyles } from '../styles/loginStyles'; // Adjust the import path as needed

const Login = ({ Email, navigateTo }) => {
  const [inputText, setInputText] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [groupedHeaders, setGroupedHeaders] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [sortAscending, setSortAscending] = useState(false); // Initialize to false to reverse the order on load
  const [isModalVisible, setIsModalVisible] = useState(false); // State to control modal visibility
  const [isAIEnabled, setIsAIEnabled] = useState(false); // State for AI toggle
  const pageSize = 10; // Number of items per page

  const navigate = Platform.OS === 'web' ? useNavigate() : null; // Initialize navigate only for web

  const handleFocus = () => {
    setIsTyping(true);
    setInputText('');
  };

  const toggleSortOrder = () => {
    setSortAscending(!sortAscending);
  };

  const flattenHeaders = (groupedHeaders) => {
    const flattened = [];
    Object.entries(groupedHeaders).forEach(([date, headers]) => {
      headers.forEach(header => {
        flattened.push({ ...header, date });
      });
    });
    return flattened;
  };

  const getHeadersForDisplay = () => {
    const allHeaders = flattenHeaders(groupedHeaders);
    const start = currentPage * pageSize;
    const end = start + pageSize;
    return allHeaders.slice(start, end);
  };

  const updateTotalPages = () => {
    const allHeaders = flattenHeaders(groupedHeaders);
    setTotalPages(Math.ceil(allHeaders.length / pageSize));
  };

  useEffect(() => {
    if (Email) {
      fetchAndDisplayUserHeaders();
    }
  }, [sortAscending, Email]);

  useEffect(() => {
    updateTotalPages();
  }, [groupedHeaders]);

  useEffect(() => {
    console.log('Grouped Headers:', groupedHeaders);
  }, [groupedHeaders]);

  const fetchAndDisplayUserHeaders = async () => {
    if (!Email) {
      return;
    }

    try {
      const response = await fetch('https://taskpads.com/backend/userheaders.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: Email }),
      });

      const data = await response.json();
      const groupedHeaders = data.taskHeaders && data.taskHeaders.length > 0
        ? groupHeadersByDate(data.taskHeaders)
        : {};

      console.log('Fetched Headers:', groupedHeaders);
      setGroupedHeaders(groupedHeaders);
    } catch (error) {
      console.error('Error fetching user headers:', error);
    }
  };

  const formatDate = (dateString) => {
    const dateParts = dateString.split(' ')[0].split('-');
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];
    return `${month}/${day}/${year.substring(2)}`;
  };

  const groupHeadersByDate = (taskHeaders) => {
    const sortedHeaders = taskHeaders.sort((a, b) => {
      const dateA = new Date(a.Creation_Date);
      const dateB = new Date(b.Creation_Date);
      return sortAscending ? dateA - dateB : dateB - dateA;
    });

    const grouped = {};
    sortedHeaders.forEach(header => {
      const formattedDateTime = header.Creation_Date;
      const formattedDate = formatDate(formattedDateTime);

      if (!grouped[formattedDate]) {
        grouped[formattedDate] = [];
      }

      grouped[formattedDate].push({
        name: header.Header_Name,
        id: header.Header_Id,
        dateTime: formattedDateTime,
      });
    });

    return grouped;
  };

  const onHeaderPress = (headerId) => {
    if (Platform.OS === 'web') {
      navigate(`/tasks/${headerId}`); // Use navigate for web navigation
    } else {
      navigateTo('HeaderDetailsPage', headerId); // Mobile navigation logic
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError('');

    const endpoint = isAIEnabled ? 'https://taskpads.com/backend/fireworksapi.php' : 'https://taskpads.com/backend/aiOffTaskPad.php';
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: Email,
          text: inputText,
        }),
      });

      const responseData = await response.json();
      console.log('API Response:', responseData); // Debug log

      if (responseData.Header_ID !== undefined) {
        console.log('Received Header_ID:', responseData.Header_ID); // Debug log
        await fetchAndDisplayUserHeaders();
        if (Platform.OS === 'web') {
          navigate(`/tasks/${responseData.Header_ID}`);
        } else {
          navigateTo('HeaderDetailsPage', responseData.Header_ID);
        }
        setIsModalVisible(false); // Close the modal on successful submission
      } else {
        setError('Error: Header ID not received');
        console.error('Error: Header ID not received', responseData); // Debug log
      }
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleTextInputChange = (text) => {
    if (!isAIEnabled && text.length > 75) {
      setError('Task pad name cannot be longer than 75 characters');
    } else {
      setError('');
      setInputText(text);
    }
  };

  const renderContent = () => (
    <ScrollView contentContainerStyle={commonStyles.scrollViewContainer}>
      <View style={commonStyles.scrollViewContent}>
        <View style={commonStyles.contentBox}>
          <Text style={commonStyles.header}>TaskPads</Text>
        </View>
        <TouchableOpacity onPress={() => setIsModalVisible(true)} style={commonStyles.newTaskPadButton}>
            <Text style={commonStyles.newTaskPadButtonText}>Create a Pad</Text>
          </TouchableOpacity>

        <View>
         
          <Modal
            visible={isModalVisible}
            transparent={true}
            animationType="slide"
          >
            <View style={commonStyles.modalOverlay}>
              <View style={commonStyles.modalContainer}>
                <Text style={commonStyles.label}>Create a Task Pad to:</Text>
                <TextInput
                  style={commonStyles.input}
                  onChangeText={handleTextInputChange}
                  value={inputText}
                  onFocus={handleFocus}
                  placeholder={isAIEnabled ? "Describe the lists of tasks to be created" : "Enter task pad name"}
                />
                <View style={commonStyles.toggleContainer}>
                  <Text style={commonStyles.toggleLabel}>Create With AI</Text>
                  <Switch
                    value={isAIEnabled}
                    onValueChange={setIsAIEnabled}
                    style={commonStyles.toggleSwitch}
                  />
                </View>

                {loading && <ActivityIndicator size="large" color="#0000ff" />}
                {error && <Text style={commonStyles.validationText}>{error}</Text>}
                <View style={commonStyles.modalButtonContainer}>
                  <TouchableOpacity onPress={handleSubmit} style={commonStyles.submitButton}>
                    <Text style={commonStyles.submitButtonText}>Submit</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => setIsModalVisible(false)} style={commonStyles.cancelButton}>
                    <Text style={commonStyles.cancelButtonText}>Cancel</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
        </View>

        <View style={commonStyles.headerListBox}>
          <TouchableOpacity onPress={toggleSortOrder} style={commonStyles.sortButton}>
            <Text style={commonStyles.sortButtonText}>
              Sort by Date {sortAscending ? 'Descending' : 'Ascending'}
            </Text>
          </TouchableOpacity>
          {getHeadersForDisplay().map((header, index, array) => {
            const isStartDate = index === 0 || header.date !== array[index - 1].date;
            return (
              <View key={header.id} style={commonStyles.headerContainer}>
                {isStartDate && (
                  <View style={commonStyles.dateHeader}>
                    <Text style={commonStyles.dateHeaderText}>{header.date}</Text>
                  </View>
                )}
                <TouchableOpacity style={commonStyles.headerBox} onPress={() => onHeaderPress(header.id)}>
                  <Text style={commonStyles.headerNameText}>{header.name}</Text>
                </TouchableOpacity>
              </View>
            );
          })}
          <View style={commonStyles.paginationContainer}>
            {Array.from({ length: totalPages }, (_, i) => (
              <TouchableOpacity key={i} onPress={() => handlePageChange(i)} style={commonStyles.pageButton}>
                <Text style={commonStyles.pageButtonText}>{i + 1}</Text>
              </TouchableOpacity>
            ))}
          </View>
        </View>
      </View>
    </ScrollView>
  );

  return (
    Platform.OS === 'web' ? (
      <div style={webStyles.background}>
        {renderContent()}
      </div>
    ) : (
      <ImageBackground source={require('../assets/background.png')} style={commonStyles.background} resizeMode="repeat">
        {renderContent()}
      </ImageBackground>
    )
  );
};

export default Login;
