import React, { useState, useEffect } from 'react';
import { Text, View, Platform, Alert, Dimensions } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import config from '../auth0-configuration';
import Login from './Login';
import LoggedOut from './LoggedOut';
import { styles, webStyles } from '../styles/styles'; // Adjust the import path as needed
import { useAuth } from '../App';


let useAuth0, Auth0Provider, auth0;

if (Platform.OS === 'web') {
  const { useAuth0: useWebAuth0, Auth0Provider: WebAuth0Provider } = require('@auth0/auth0-react');
  useAuth0 = useWebAuth0;
  Auth0Provider = ({ children }) => (
    <WebAuth0Provider
      domain={config.domain}
      clientId={config.clientId}
      redirectUri={window.location.origin}
    >
      {children}
    </WebAuth0Provider>
  );
} else {
  const Auth0 = require('react-native-auth0').default;
  auth0 = new Auth0({
    domain: config.domain,
    clientId: config.clientId,
  });

  const authorize = async () => await auth0.webAuth.authorize({ scope: 'openid profile email' });
  const clearSession = async () => await auth0.webAuth.clearSession();
  const getCredentials = async () => {
    try {
      const credentials = await auth0.credentialsManager.getCredentials();
      return credentials;
    } catch (error) {
      console.log('No credentials were previously set');
      return null;
    }
  };

  const fetchUserProfile = async (accessToken) => {
    try {
      const response = await axios.get(`https://${config.domain}/userinfo`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching user profile:', error);
      return null;
    }
  };

  const useNativeAuth0 = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { user, setUser } = useAuth();

    const fetchUser = async () => {
      try {
        setIsLoading(true);
        const credentials = await getCredentials();
        if (credentials) {
          const userInfo = await fetchUserProfile(credentials.accessToken);
          setUser(userInfo);
          await AsyncStorage.setItem('userProfile', JSON.stringify(userInfo));
        }
        setIsLoading(false);
      } catch (e) {
        setError(e);
        setIsLoading(false);
      }
    };

    useEffect(() => {
      fetchUser();
    }, []);

    return { authorize, clearSession, getCredentials, user, error, isLoading, setUser, fetchUser, fetchUserProfile };
  };

  useAuth0 = useNativeAuth0;
  Auth0Provider = ({ children }) => children;
}

const showAlert = (title, message) => {
  if (Platform.OS === 'web') {
    alert(`${title}: ${message}`);
  } else {
    Alert.alert(title, message);
  }
};

const Home = ({ navigateTo, setUser }) => {
  const { user, error, isLoading, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const { setUser: setAppUser } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkLoggedInStatus = async () => {
      try {
        console.log('Checking logged-in status...');
        let storedUserProfile;
        if (Platform.OS === 'web') {
          console.log('Platform: web');
          storedUserProfile = localStorage.getItem('userProfile');
          if (storedUserProfile) {
            console.log('Stored user profile found:', storedUserProfile);
          } else {
            console.log('No stored user profile found');
          }
        } else {
          console.log('Platform: native');
          storedUserProfile = await AsyncStorage.getItem('userProfile');
        }

        if (storedUserProfile) {
          const parsedUserProfile = JSON.parse(storedUserProfile);
          console.log('Parsed user profile:', parsedUserProfile);
          setUser(parsedUserProfile);
          setAppUser(parsedUserProfile); // Update the context user state
        } else {
          console.log('No user profile found');
        }
      } catch (error) {
        console.error('Failed to retrieve user data:', error);
      } finally {
        setLoading(false);
      }
    };

    checkLoggedInStatus();
  }, [setUser, setAppUser]);

  useEffect(() => {
    console.log('Auth0 user:', user);
    if (user) {
      setUser(user);
      setAppUser(user); // Update the context user state
    }
    console.log('Auth0 error:', error);
    console.log('Auth0 isLoading:', isLoading);
  }, [user, error, isLoading, setUser, setAppUser]);

  console.log("userhome", user);

  if (loading || isLoading) {
    return <View style={styles.container}><Text>Loading...</Text></View>;
  }

  return (
    <>
      {user ? (
        <Login Email={user.email} navigateTo={navigateTo} /> // Ensure navigateTo prop is passed
      ) : (
        <LoggedOut />
      )}
      {error && <Text style={styles.responseMessage}>{error.message}</Text>}
    </>
  );
};

export { useAuth0 };


export default Home;
