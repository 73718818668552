import React, { createContext, useContext, useState, useEffect } from 'react';
import { Platform, View, Text } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import config from './auth0-configuration';
import { NavigationContainer } from '@react-navigation/native';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { routes, screens, Navbar } from './screens';
import PostDetailsPage from './pages/PostDetailsPage'; // Import the PostDetailsPage component
import HeaderDetailsPage from './pages/HeaderDetailsPage'; // Import the HeaderDetailsPage component
import Footer from './navigation/Footer'; // Import the Footer component

const AuthContext = createContext();

const useAuth = () => useContext(AuthContext);

let Auth0Provider;
if (Platform.OS === 'web') {
  const { Auth0Provider: WebAuth0Provider } = require('@auth0/auth0-react');
  Auth0Provider = ({ children }) => (
    <WebAuth0Provider
      domain={config.domain}
      clientId={config.clientId}
      redirectUri={window.location.origin}
    >
      {children}
    </WebAuth0Provider>
  );
} else {
  Auth0Provider = ({ children }) => children;
}

const App = () => {
  const [currentScreen, setCurrentScreen] = useState('Home');
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentHeaderId, setCurrentHeaderId] = useState(null);
  const [currentTaskId, setCurrentTaskId] = useState(null); // For mobile task detail navigation

  const storeVariableInLocalStorage = async (key, value) => {
    try {
      const stringValue = JSON.stringify(value);
      if (Platform.OS === 'web') {
        localStorage.setItem(key, stringValue);
      } else {
        await AsyncStorage.setItem(key, stringValue);
      }
      console.log('Variable stored successfully');
    } catch (error) {
      console.error('Error storing variable:', error);
    }
  };

  const logVariableFromLocalStorage = async (key) => {
    try {
      let value;
      if (Platform.OS === 'web') {
        value = localStorage.getItem(key);
      } else {
        value = await AsyncStorage.getItem(key);
      }
      if (value !== null) {
        console.log('Retrieved value:', JSON.parse(value));
      } else {
        console.log('No value found for key:', key);
      }
    } catch (error) {
      console.error('Error retrieving variable:', error);
    }
  };

  useEffect(() => {
    const checkLoggedInStatus = async () => {
      try {
        let storedUserProfile;
        if (Platform.OS === 'web') {
          storedUserProfile = localStorage.getItem('userProfile');
        } else {
          storedUserProfile = await AsyncStorage.getItem('userProfile');
        }

        if (storedUserProfile) {
          setUser(JSON.parse(storedUserProfile));
        } else {
          console.log('No user profile found');
        }
      } catch (error) {
        console.error('Failed to retrieve user data:', error);
      } finally {
        setLoading(false);
      }
    };

    checkLoggedInStatus();
  }, []);

  useEffect(() => {
    if (user !== null) {
      storeVariableInLocalStorage('userProfile', user);
    }
    logVariableFromLocalStorage('userProfile');
  }, [user]);

  const navigateTo = (screen, headerId = null, taskId = null) => {
    console.log(`Navigating to screen: ${screen}, with headerId: ${headerId}, taskId: ${taskId}`);
    setCurrentScreen(screen);
    setCurrentHeaderId(headerId);
    setCurrentTaskId(taskId); // Set the current task ID for mobile navigation
  };

  if (loading) {
    console.log('App is loading...');
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Text>Loading...</Text>
      </View>
    );
  }

  const ScreenComponent = screens[currentScreen];

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      <Auth0Provider>
        {Platform.OS === 'web' ? (
          <Router>
            <Navbar setCurrentScreen={setCurrentScreen} />
            <Routes>
              {routes.map(({ path, component: Component }) => (
                <Route 
                  key={path}
                  path={path}
                  element={<Component navigateTo={navigateTo} setUser={setUser} user={user} />}
                />
              ))}
              <Route
                path="/tasks/:taskId"
                element={<PostDetailsPage user={user} setUser={setUser} />}
              />
              <Route
                path="/post-details/:taskId"
                element={<PostDetailsPage user={user} setUser={setUser} />}
              />
              <Route
                path="/header-details/:headerId"
                element={<HeaderDetailsPage user={user} setUser={setUser} />}
              />
            </Routes>
            <Footer /> {/* Include the Footer component */}
          </Router>
        ) : (
          <NavigationContainer>
            <Navbar setCurrentScreen={setCurrentScreen} />
            {currentScreen === 'PostDetailsPage' ? (
              <PostDetailsPage taskRoute={currentTaskId} headerId={currentHeaderId} navigateTo={navigateTo} user={user} setUser={setUser} />
            ) : (
              currentScreen === 'HeaderDetailsPage' ? (
                <HeaderDetailsPage headerId={currentHeaderId} navigateTo={navigateTo} setUser={setUser} user={user} />
              ) : (
                ScreenComponent && (
                  <ScreenComponent
                    setUser={setUser}
                    navigateTo={navigateTo}
                    headerId={currentScreen === 'HeaderDetailsPage' ? currentHeaderId : undefined}
                    user={user}
                  />
                )
              )
            )}
          </NavigationContainer>
        )}
      </Auth0Provider>
    </AuthContext.Provider>
  );
};

export { useAuth };
export default App;
