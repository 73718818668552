import React, { useEffect, useState, useRef } from 'react';
import { View, Text, TouchableOpacity, Image, TextInput, Platform, Button, ImageBackground } from 'react-native';
import StylesPost, { webStyles } from '../styles/PostDetailsPageStyles'; // Import the unified styles
import { Helmet } from 'react-helmet'; // Import Helmet

// For web navigation
import { useParams, useNavigate, useLocation } from 'react-router-dom';

const PostDetailsPage = ({ taskRoute, navigateTo, editMode }) => {
  const [taskDetails, setTaskDetails] = useState(null);
  const [isEditing, setIsEditing] = useState(editMode || false);
  const [editedTask, setEditedTask] = useState({});
  const [descriptionHeight, setDescriptionHeight] = useState(40); // Initial height of the text input
  const descriptionInputRef = useRef(null);

  // Determine the taskId based on the platform
  const taskId = Platform.OS === 'web' ? useParams().taskId : taskRoute;

  // Web navigation hooks
  const navigate = Platform.OS === 'web' ? useNavigate() : null;
  const location = Platform.OS === 'web' ? useLocation() : null;

  useEffect(() => {
    const fetchTaskDetails = async () => {
      try {
        const response = await fetch(`https://taskpads.com/backend/postsave.php?Task_Id=${taskId}`);
        const data = await response.json();

        if (response.ok) {
          setTaskDetails(data.task);
          setEditedTask(data.task);

          if (Platform.OS === 'web') {
            // Update SEO props dynamically
            document.title = data.task.Task_Name || 'Task Details';
            document.querySelector('meta[name="description"]').setAttribute("content", data.task.Task_Description || 'No description available');
            document.querySelector('meta[name="keywords"]').setAttribute("content", 'task, details, ' + (data.task.Task_Name || '').split(' ').join(', '));

            if (location.search.includes('edit=true')) {
              setIsEditing(true);
            }
          }
        } else {
          console.error('Error fetching task details:', data.message);
        }
      } catch (error) {
        console.error('Error fetching task details:', error);
      }
    };

    fetchTaskDetails();
  }, [taskId, location]);

  useEffect(() => {
    if (isEditing && descriptionInputRef.current) {
      descriptionInputRef.current.focus();
    }
  }, [isEditing]);

  const handleSave = async () => {
    try {
      const response = await fetch('https://taskpads.com/backend/postsave.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          Task_Id: taskId,
          Task_Name: editedTask.Task_Name,
          Task_Description: editedTask.Task_Description
        })
      });

      if (response.ok) {
        if (Platform.OS === 'web') {
          navigate(`/tasks/${taskDetails.Header_Id}`);
        } else {
          navigateTo('HeaderDetailsPage', taskDetails.Header_Id);
        }
      } else {
        const data = await response.json();
        console.error('Error saving task:', data.message);
      }
    } catch (error) {
      console.error('Error saving task:', error);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await fetch('https://taskpads.com/backend/postsave.php', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ Task_Id: taskId })
      });

      if (response.ok) {
        handleBack();
      } else {
        const data = await response.json();
        console.error('Error deleting task:', data.message);
      }
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  // Handle back navigation
  const handleBack = () => {
    if (Platform.OS === 'web') {
      if (taskDetails && taskDetails.Header_Id) {
        navigate(`/tasks/${taskDetails.Header_Id}`);
      } else {
        navigate('/home'); // Fallback in case Header_Id is not available
      }
    } else {
      // Handle back navigation for mobile
      if (taskDetails && taskDetails.Header_Id) {
        navigateTo('HeaderDetailsPage', taskDetails.Header_Id);
      } else {
        navigateTo('Home'); // Fallback in case Header_Id is not available
      }
    }
  };

  const handleChange = (field, value) => {
    setEditedTask({
      ...editedTask,
      [field]: value,
    });
  };

  const handleContentSizeChange = (contentWidth, contentHeight) => {
    setDescriptionHeight(contentHeight);
  };

  // Render task details
  const renderTaskDetails = () => (
    <View style={StylesPost.draggableItem}>
      <View style={StylesPost.headerRow}>
        {isEditing ? (
          <TextInput
            style={[
              StylesPost.taskNameEditing, // Changed style
              { minWidth: '100%', maxWidth:380, }
            ]}
            value={editedTask.Task_Name}
            onChangeText={(text) => handleChange('Task_Name', text)}
            placeholder="Enter Title"
          />
        ) : (
          <Text style={StylesPost.taskName}>{taskDetails.Task_Name || 'Enter Title'}</Text>
        )}
      </View>
      {isEditing ? (
        <TextInput
          ref={descriptionInputRef}
          style={[
            StylesPost.textArea,
            { width: '100%', maxWidth: 400, backgroundColor: '#f0f8ff', padding: 10, height: descriptionHeight } // Highlighting edit text
          ]}
          value={editedTask.Task_Description}
          onChangeText={(text) => handleChange('Task_Description', text)}
          placeholder="Enter Description"
          multiline
          onContentSizeChange={(e) => handleContentSizeChange(e.nativeEvent.contentSize.width, e.nativeEvent.contentSize.height)}
        />
      ) : (
        <Text style={StylesPost.taskDescription}>
          {taskDetails.Task_Description || 'Enter Description'}
        </Text>
      )}
      <Text style={StylesPost.taskDescription}>
        Created: {taskDetails.Creation_Date}
      </Text>
      <Text style={StylesPost.taskDescription}></Text>

      <View style={StylesPost.editPostButton}>
        {isEditing ? (
          <View style={StylesPost.buttonRow}>
            <Button title="Save" onPress={handleSave} />
            <Button title="Cancel" onPress={() => setIsEditing(false)} />
          </View>
        ) : (
          <>
            {Platform.OS === 'web' ? (
              <div style={StylesPost.actionIcons}>
                <img src="../assets/edit.png" alt="Edit" style={StylesPost.icon} onClick={() => setIsEditing(true)} />
                <img src="/assets/trash.png" alt="Trash" style={StylesPost.icon} onClick={handleDelete} />
              </div>
            ) : (
              <View style={StylesPost.actionIcons}>
                <TouchableOpacity onPress={() => setIsEditing(true)}>
                  <Image source={require('../assets/edit.png')} style={StylesPost.icon} />
                </TouchableOpacity>
                <TouchableOpacity onPress={handleDelete}>
                  <Image source={require('../assets/trash.png')} style={StylesPost.icon} />
                </TouchableOpacity>
              </View>
            )}
          </>
        )}
      </View>
    </View>
  );

  const renderContent = () => (
    <View style={StylesPost.container}>
      {taskDetails ? (
        <>
          <View style={StylesPost.containerHeader}>
            <View style={StylesPost.headerContainer}>
              <TouchableOpacity onPress={handleBack} style={StylesPost.backButton}>
                <Text style={StylesPost.backIcon}>◄</Text>
              </TouchableOpacity>
              <Text onPress={handleBack} style={StylesPost.headerTitle}>{taskDetails.Header_Name}</Text>
              <View style={StylesPost.placeholder} />
            </View>
          </View>
          <View style={StylesPost.contentBox}>
            <View style={StylesPost.droppableContainer}>
              {renderTaskDetails()}
            </View>
          </View>
        </>
      ) : (
        <Text>Loading task details...</Text>
      )}
    </View>
  );

  return (
    <>
      {Platform.OS === 'web' ? (
        <>
          <Helmet>
            <title>{taskDetails ? `${taskDetails.Task_Name} - ${taskDetails.Header_Name} - TaskPads` : 'TaskPads'}</title>
          </Helmet>
          <div style={webStyles.background}>
            {renderContent()}
          </div>
        </>
      ) : (
        <ImageBackground source={require('../assets/background.png')} style={StylesPost.background} resizeMode="repeat">
          {renderContent()}
        </ImageBackground>
      )}
    </>
  );
};

export default PostDetailsPage;
