import React, { useEffect, useState } from 'react';
import { View, Text, TextInput, Modal, TouchableOpacity, ScrollView, Platform, ImageBackground, StyleSheet } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useAuth } from '../App';
import { styles, webStyles } from '../styles/profileStyles'; // Adjust the import path as needed
import AvatarGenerator from './AvatarGenerator'; // Adjust the import path as needed
import CustomButton from './components/CustomButton'; // Adjust the import path as needed

const timezones = ["Pacific", "Central", "Eastern", "Mountain"];

const Profile = () => {
  const { user, setUser } = useAuth();
  const [userName, setUserName] = useState('');
  const [newUserName, setNewUserName] = useState('');
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(null);
  const [timezone, setTimezone] = useState('Pacific');
  const [avatarSeed, setAvatarSeed] = useState('');
  const [email, setEmail] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (user) {
      console.log('User found:', user);
      setUserName(user.name || 'User');
      setEmail(user.email || '');
      setNewUserName(user.username || '');
      setTimezone(user.timezone || 'Pacific');
    } else {
      console.log('No user found in context');
    }
  }, [user]);

  useEffect(() => {
    const fetchUserData = async () => {
      let storedUserProfile = null;
      try {
        if (Platform.OS === 'web') {
          storedUserProfile = localStorage.getItem('userProfile');
        } else {
          storedUserProfile = await AsyncStorage.getItem('userProfile');
        }

        if (storedUserProfile) {
          const userProfile = JSON.parse(storedUserProfile);
          console.log('Stored user profile found:', userProfile);
          setEmail(userProfile.email || '');

          let profileData = null;
          if (Platform.OS === 'web') {
            profileData = localStorage.getItem('profileData');
          } else {
            profileData = await AsyncStorage.getItem('profileData');
          }

          if (profileData && profileData !== 'undefined') {
            console.log('Profile data found in cache:', profileData);
            const data = JSON.parse(profileData);
            setUserName(data.username || 'User');
            setNewUserName(data.username || '');
            setTimezone(data.timezone || 'Pacific');
            setAvatarSeed(data.avatar_seed || '');
          } else {
            console.log('Profile data not found in cache or is undefined, fetching from server...');
            const response = await fetch(`https://taskpads.com/backend/retrieve-profile.php`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ email: userProfile.email }),
            });

            const data = await response.json();

            if (data.success) {
              console.log('Profile data retrieved from server:', data);
              setUserName(data.profile.username || 'User');
              setNewUserName(data.profile.username || '');
              setTimezone(data.profile.timezone || 'Pacific');
              setAvatarSeed(data.profile.avatar_seed || '');
              if (Platform.OS === 'web') {
                localStorage.setItem('profileData', JSON.stringify(data.profile));
              } else {
                await AsyncStorage.setItem('profileData', JSON.stringify(data.profile));
              }
            } else {
              console.error('Error retrieving profile from server:', data.message);
              setResponseMessage(data.message);
            }
          }
        } else {
          console.log('No stored user profile found');
          setUserName('User');
        }
      } catch (error) {
        console.error('Error in fetchUserData:', error);
        setResponseMessage('Error retrieving profile');
      }
    };

    fetchUserData();
  }, []);

  const validateUsername = async () => {
    try {
      console.log('Validating username:', newUserName);
      const response = await fetch(`https://taskpads.com/backend/validate-username.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: newUserName }),
      });

      const data = await response.json();
      console.log('Username validation response:', data);
      setIsUsernameAvailable(data.available);
      setResponseMessage(data.available ? 'Username is available' : 'Username is taken');
    } catch (error) {
      console.error('Error validating username:', error);
      setResponseMessage('Error validating username');
    }
  };

  const saveChanges = async () => {
    try {
      console.log('Saving profile changes...');
      const response = await fetch(`https://taskpads.com/backend/save-profile.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          username: newUserName,
          timezone: timezone,
          avatarSeed: avatarSeed,
        }),
      });

      const data = await response.json();
      console.log('Save profile response:', data);
      if (data.success) {
        setResponseMessage('Profile updated successfully');
        const updatedUserProfile = {
          ...user,
          username: newUserName || user.username,
          timezone: timezone || user.timezone,
          avatarSeed: avatarSeed || user.avatarSeed,
        };

        console.log('Updated user profile to save:', updatedUserProfile);

        if (Platform.OS === 'web') {
          localStorage.setItem('userProfile', JSON.stringify(updatedUserProfile));
          localStorage.setItem('profileData', JSON.stringify(data.profile));
        } else {
          if (updatedUserProfile && data.profile) {
            if (
              updatedUserProfile.email &&
              updatedUserProfile.username &&
              updatedUserProfile.timezone &&
              updatedUserProfile.avatarSeed &&
              data.profile.email &&
              data.profile.username &&
              data.profile.timezone &&
              data.profile.avatar_seed
            ) {
              await AsyncStorage.setItem('userProfile', JSON.stringify(updatedUserProfile));
              await AsyncStorage.setItem('profileData', JSON.stringify(data.profile));
            } else {
              console.error('Cannot save null/undefined values');
              console.log('Values:', {
                updatedUserProfile,
                dataProfile: data.profile,
              });
            }
          } else {
            console.error('Cannot save null/undefined values');
            console.log('Values:', {
              updatedUserProfile,
              dataProfile: data.profile,
            });
          }
        }
      } else {
        setResponseMessage(data.message);
      }
    } catch (error) {
      console.error('Error saving profile:', error);
      setResponseMessage('Error saving profile');
    }
  };

  const renderPicker = () => {
    if (Platform.OS === 'web') {
      return (
        <select
          value={timezone}
          onChange={(e) => setTimezone(e.target.value)}
          style={styles.select}
        >
          {timezones.map((tz) => (
            <option key={tz} value={tz}>
              {tz}
            </option>
          ))}
        </select>
      );
    } else {
      return (
        <>
          <TouchableOpacity onPress={() => setIsModalVisible(true)} style={styles.input}>
            <Text>{timezone}</Text>
          </TouchableOpacity>
          <Modal
            visible={isModalVisible}
            transparent={true}
            animationType="slide"
          >
            <View style={styles.modalOverlay}>
              <View style={styles.modalContent}>
                <ScrollView>
                  {timezones.map((tz) => (
                    <TouchableOpacity
                      key={tz}
                      onPress={() => {
                        setTimezone(tz);
                        setIsModalVisible(false);
                      }}
                      style={styles.modalItem}
                    >
                      <Text>{tz}</Text>
                    </TouchableOpacity>
                  ))}
                </ScrollView>
              </View>
            </View>
          </Modal>
        </>
      );
    }
  };

  const renderContent = () => (
    <View style={styles.background}>
      {userName ? (
        <>
          <View style={styles.headerBox}>
            <Text style={styles.label}>Username</Text>
            <TextInput
              style={styles.input}
              placeholder="Enter new username"
              value={newUserName}
              onChangeText={setNewUserName}
            />
            <CustomButton onPress={validateUsername} style={styles.validateButton}>
              Validate Username
            </CustomButton>
            {isUsernameAvailable !== null && (
              <Text style={styles.validationText}>
                {isUsernameAvailable ? 'Username is available' : 'Username is taken'}
              </Text>
            )}
          </View>
          <View style={styles.headerBox}>
            <AvatarGenerator seed={avatarSeed} setSeed={setAvatarSeed} />
          </View>
          <View style={styles.headerBox}>
            <Text style={styles.label}>Timezone</Text>
            {renderPicker()}
          </View>
          <CustomButton onPress={saveChanges} style={styles.saveButton}>
            Save Changes
          </CustomButton>
          {responseMessage && <Text style={styles.responseMessage}>{responseMessage}</Text>}
        </>
      ) : (
        <Text style={styles.text}>No user data available.</Text>
      )}
    </View>
  );

  return (
    Platform.OS === 'web' ? (
      <div style={webStyles.background}>
        {renderContent()}
      </div>
    ) : (
      <ImageBackground source={require('../assets/background.png')} style={styles.background} resizeMode="repeat">
        {renderContent()}
      </ImageBackground>
    )
  );
};


const seoProps = {
  title: 'Profile Page',
  description: 'This is the profile page of our application.',
  keywords: 'profile, user, react, seo'
};

export default Profile;
