import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, TouchableOpacity, Image, ScrollView, LogBox, Modal, Dimensions, Platform, ImageBackground, StyleSheet } from 'react-native';
import SortableList from 'react-native-sortable-list';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'; // For web drag-and-drop
import { webStyles, mobileStyles } from '../styles/DraggableListStyles'; // Import the mobileStyles from the separate file
import { useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate and useLocation

const DraggableList = ({ data, onDragEnd, onEditConfirm, headerId, navigateTo, onNewTask }) => {
  if (Platform.OS === 'web') {
    return <WebDraggableList data={data} onDragEnd={onDragEnd} onEditConfirm={onEditConfirm} headerId={headerId} onNewTask={onNewTask} />;
  } else {
    return <MobileDraggableList data={data} onDragEnd={onDragEnd} onEditConfirm={onEditConfirm} navigateTo={navigateTo} headerId={headerId} onNewTask={onNewTask} />;
  }
};

const truncateText = (text, charLimit) => {
  if (!text) return '';
  if (text.length > charLimit) {
    return text.substring(0, charLimit) + '...';
  }
  return text;
};

const WebDraggableList = ({ data, onDragEnd, onEditConfirm, headerId, onNewTask }) => {
  const [items, setItems] = useState(data);
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);
  const [newTaskName, setNewTaskName] = useState('');
  const [newTaskDescription, setNewTaskDescription] = useState('');
  const navigate = useNavigate(); // Use useNavigate hook
  const location = useLocation(); // Use useLocation hook

  useEffect(() => {
    setItems(data);
  }, [data]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(items);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);

    setItems(reorderedItems);
    onDragEnd(reorderedItems);
  };

  const openEditMode = (item) => {
    navigate(`/post-details/${item.Task_Id}`, { state: { task: item, headerId: headerId, from: location.pathname } });
  };

  const handleNewTask = async () => {
    if (!headerId) {
      console.error('Header ID is undefined');
      return;
    }
    
    try {
      const response = await fetch('https://taskpads.com/backend/createTask.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ Header_Id: headerId.toString(), Task_Name: newTaskName, Task_Description: newTaskDescription })
      });

      const newTask = await response.json();

      if (response.ok) {
        setItems((prevItems) => [...prevItems, newTask]);
        onNewTask(newTask);
        setShowAddTaskModal(false);
        setNewTaskName('');
        setNewTaskDescription('');
      } else {
        console.error('Error creating task:', newTask.message);
      }
    } catch (error) {
      console.error('Error creating task:', error);
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} style={webStyles.droppableContainer}>
              {items.map((item, index) => (
                <Draggable key={item.Task_Id} draggableId={item.Task_Id.toString()} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={{
                        ...webStyles.draggableItem,
                        ...provided.draggableProps.style,
                        ...(snapshot.isDragging ? webStyles.draggingItem : {}),
                      }}
                    >
                      <div {...provided.dragHandleProps} style={webStyles.headerRow}>
                        <Text style={webStyles.taskName}>{item.Task_Name}</Text>
                        <img src="../assets/drag.png" alt="Drag" style={webStyles.icon} />
                      </div>
                      <p style={webStyles.taskDescription}>{truncateText(item.Task_Description, 110)}</p>
                      <div style={webStyles.actionIcons2}>
                        <TouchableOpacity onPress={() => openEditMode(item)} style={webStyles.downArrow}>
                          <Text>&#9660;</Text>
                        </TouchableOpacity>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              <TouchableOpacity onPress={() => setShowAddTaskModal(true)} style={webStyles.addButton}>
                <Text style={webStyles.addButtonText}>Add New</Text>
              </TouchableOpacity>
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {showAddTaskModal && (
        <div style={webStyles.modal}>
          <div style={webStyles.modalContent}>
            <Text>Add New Task</Text>
            <TextInput
              placeholder="Task Name"
              value={newTaskName}
              onChange={(e) => setNewTaskName(e.target.value)}
              style={webStyles.input}
            />
            <TextInput
              placeholder="Task Description"
              value={newTaskDescription}
              onChange={(e) => setNewTaskDescription(e.target.value)}
              style={webStyles.textArea}
            />
            <div style={webStyles.modalActions}>
              <button onClick={handleNewTask} style={webStyles.modalButton}>Save</button>
              <button onClick={() => setShowAddTaskModal(false)} style={webStyles.modalButton}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const MobileDraggableList = ({ data, onDragEnd, onEditConfirm, navigateTo, headerId, onNewTask }) => {
  const [items, setItems] = useState(data);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [editedTaskName, setEditedTaskName] = useState('');
  const [editedTaskDescription, setEditedTaskDescription] = useState('');
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);
  const [newTaskName, setNewTaskName] = useState('');
  const [newTaskDescription, setNewTaskDescription] = useState('');
  const screenHeight = Dimensions.get('window').height;

  useEffect(() => {
    LogBox.ignoreLogs(['Animated: `useNativeDriver`']);
  }, []);

  useEffect(() => {
    setItems(data);
  }, [data]);

  useEffect(() => {
    const editingItem = items.find(item => item.isEditing);
    if (editingItem) {
      setItemToEdit(editingItem);
      setEditedTaskName(editingItem.Task_Name);
      setEditedTaskDescription(editingItem.Task_Description);
    }
  }, [items]);

  const handleSaveEdit = async () => {
    try {
      const response = await fetch('https://taskpads.com/backend/postsave.php', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ Task_Id: itemToEdit.Task_Id, Task_Name: editedTaskName, Task_Description: editedTaskDescription })
      });

      const responseData = await response.json();

      if (response.ok) {
        console.log('Task updated:', itemToEdit.Task_Id);
        onEditConfirm(itemToEdit.Task_Id, editedTaskName, editedTaskDescription); // Notify the parent component
        setItemToEdit(null);
      } else {
        console.error('Error updating task:', responseData.message);
      }
    } catch (error) {
      console.error('Error updating task:', error);
    }
  };

  const openEditMode = (item) => {
    navigateTo('PostDetailsPage', headerId, item.Task_Id);
  };

  const cancelEdit = () => {
    setItemToEdit(null);
  };

  const handleReleaseRow = (key, currentOrder) => {
    const reorderedItems = currentOrder.map(index => items[index]);
    setItems(reorderedItems);
    onDragEnd(reorderedItems);
  };

  const handleNewTask = async () => {
    if (!headerId) {
      console.error('Header ID is undefined');
      return;
    }

    try {
      const response = await fetch('https://taskpads.com/backend/createTask.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ Header_Id: headerId.toString(), Task_Name: newTaskName, Task_Description: newTaskDescription })
      });

      const newTask = await response.json();

      if (response.ok) {
        setItems((prevItems) => [...prevItems, newTask]);
        onNewTask(newTask);
        setShowAddTaskModal(false);
        setNewTaskName('');
        setNewTaskDescription('');
      } else {
        console.error('Error creating task:', newTask.message);
      }
    } catch (error) {
      console.error('Error creating task:', error);
    }
  };

  return (
    <ImageBackground source={require('../assets/background.png')} style={mobileStyles.background} resizeMode="repeat">
      <View style={[mobileStyles.flexContainer, itemToEdit && { height: screenHeight, overflow: 'hidden' }]}>
        <SortableList
          style={[mobileStyles.list, itemToEdit && { height: screenHeight }]}
          contentContainerStyle={mobileStyles.contentContainer}
          data={items}
          renderRow={({ data, active }) => (
            itemToEdit && itemToEdit.Task_Id !== data.Task_Id ? null : (
              <View style={[mobileStyles.item, active && mobileStyles.activeItem, { marginBottom: 12 }]}>
                {itemToEdit && itemToEdit.Task_Id === data.Task_Id ? (
                  <View style={[mobileStyles.editBox, { height: screenHeight }]}>
                    <TextInput
                      value={editedTaskName}
                      onChangeText={setEditedTaskName}
                      style={mobileStyles.input}
                    />
                    <TextInput
                      value={editedTaskDescription}
                      onChangeText={setEditedTaskDescription}
                      style={[mobileStyles.input, mobileStyles.textArea]}
                      multiline={true}
                    />
                    <View style={mobileStyles.editActions}>
                      <TouchableOpacity onPress={handleSaveEdit} style={mobileStyles.modalButton}>
                        <Text style={mobileStyles.modalButtonText}>Save</Text>
                      </TouchableOpacity>
                      <TouchableOpacity onPress={cancelEdit} style={mobileStyles.modalButton}>
                        <Text style={mobileStyles.modalButtonText}>Cancel</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                ) : (
                  <View style={mobileStyles.droppableContainer}>
               
                      <View style={mobileStyles.headerRow}>
                        <Text style={mobileStyles.taskName}>{data.Task_Name}</Text>
                        <Image source={require('../assets/drag.png')} style={mobileStyles.icon} />
                      </View>
                      <Text style={mobileStyles.taskDescription}>{truncateText(data.Task_Description, 100)}</Text>
                      <View style={mobileStyles.actionIcons2}>
                        <TouchableOpacity onPress={() => openEditMode(data)} style={mobileStyles.downArrow}>
                          <Text>&#9660;</Text>
                        </TouchableOpacity>
                      </View>
                
                  </View>
                )}
              </View>
            )
          )}
          onReleaseRow={handleReleaseRow}
        />

        <TouchableOpacity onPress={() => setShowAddTaskModal(true)} style={mobileStyles.addButton}>
          <Text style={mobileStyles.addButtonText}>Add Task</Text>
        </TouchableOpacity>

        <Modal
          visible={showAddTaskModal}
          transparent={true}
          animationType="slide"
        >
          <View style={mobileStyles.modal}>
            <View style={mobileStyles.modalContent}>
              <Text>Add New Task</Text>
              <TextInput
                placeholder="Task Name"
                value={newTaskName}
                onChangeText={setNewTaskName}
                style={mobileStyles.input}
              />
              <TextInput
                placeholder="Task Description"
                value={newTaskDescription}
                onChangeText={setNewTaskDescription}
                style={[mobileStyles.input, mobileStyles.textArea]}
                multiline={true}
              />
              <View style={mobileStyles.modalActions}>
                <TouchableOpacity onPress={handleNewTask} style={mobileStyles.modalButton}>
                  <Text style={mobileStyles.modalButtonText}>Save</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => setShowAddTaskModal(false)} style={mobileStyles.modalButton}>
                  <Text style={mobileStyles.modalButtonText}>Cancel</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>
      </View>
    </ImageBackground>
  );
};

export default DraggableList;
