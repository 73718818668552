import { StyleSheet, Platform } from 'react-native';
import backgroundImage from '../assets/background.png';

export const styles = StyleSheet.create({
  background: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
    backgroundColor: '#f8f9fa', // Bootstrap's light background color
  },
  scrollViewContainer: {
    flexGrow: 1,
    width: '100%',
   
    alignItems: 'center',
  },
  container: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
  },
  contentBox: {
    paddingBottom: 0,
    alignItems: 'center',
  },
  header: {
    fontSize: 20, // Bootstrap font size (1.25rem)
    marginBottom: 16, // Bootstrap margin bottom (1rem)
    color: '#000', // added color
    marginTop: 20,
    width: '100%',
  },
  text: {
    fontSize: 16, // Bootstrap font size (1rem)
    marginBottom: 16, // Bootstrap margin bottom (1rem)
    color: '#000', // added color
  },
  label: {
    fontSize: 16, // Bootstrap font size (1rem)
    marginBottom: 8, // Bootstrap margin bottom (.5rem)
    marginTop: 8, // Bootstrap margin top (.5rem)
    color: '#000', // added color
    textAlign: 'center',
  },
  input: {
    height: 40, // Bootstrap input height (calc(1.5em + .75rem + 2px))
    borderColor: '#ced4da', // Bootstrap border color
    borderWidth: 1, // Bootstrap border width (1px)
    marginBottom: 16, // Bootstrap margin bottom (1rem)
    paddingVertical: 6, // Bootstrap padding (.375rem)
    width: '100%', // Adjusted width to be responsive
    maxWidth: 370,
    alignSelf: 'center', // Center the input box
    color: '#000', // Added color
  },
  select: {
    height: 40, // Bootstrap select height (calc(1.5em + .75rem + 2px))
    borderColor: '#ced4da', // Bootstrap border color
    borderWidth: 1, // Bootstrap border width (1px)
    marginBottom: 16, // Bootstrap margin bottom (1rem)
    paddingHorizontal: 12, // Bootstrap padding (.75rem)
    paddingVertical: 6, // Bootstrap padding (.375rem)
    width: '100%',
    alignSelf: 'center', // Center the input box
    maxWidth: 370,
    color: '#000', // added color
  },
  picker: {
    height: 40, // Adjusted height
    width: '100%',
    marginBottom: 16, // Bootstrap margin bottom (1rem)
    maxWidth: 250, // Bootstrap max width (15.625rem)
    color: '#000', // added color
  },
  validationText: {
    marginBottom: 16, // Bootstrap margin bottom (1rem)
    fontSize: 16, // Bootstrap font size (1rem)
    color: '#dc3545', // Bootstrap danger color
  },
  responseMessage: {
    marginTop: 16, // Bootstrap margin top (1rem)
    fontSize: 16, // Bootstrap font size (1rem)
    color: 'rgb(74, 144, 226)', // Bootstrap primary color
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: '#ffffff', // White background
    borderRadius: 4, // Bootstrap border radius (.25rem)
    padding: 20, // Bootstrap padding (1.25rem)
    width: 300, // Bootstrap width (18.75rem)
    maxHeight: '80%',
  },
  modalItem: {
    padding: 10, // Bootstrap padding (.625rem)
    borderBottomWidth: 1, // Bootstrap border width (1px)
    borderBottomColor: '#dee2e6', // Bootstrap border color
  },
  submitButton: {
    backgroundColor: 'rgb(74, 144, 226)', // Bootstrap primary color
    paddingVertical: 6, // Bootstrap padding (.375rem)
    paddingHorizontal: 12, // Bootstrap padding (.75rem)
    borderRadius: 4, // Bootstrap border radius (.25rem)
  },
  submitButtonText: {
    color: '#ffffff', // White color
    textAlign: 'center',
  },
  sortButton: {
    marginTop: 16, // Bootstrap margin top (1rem)
    paddingVertical: 6, // Bootstrap padding (.375rem)
    paddingHorizontal: 12, // Bootstrap padding (.75rem)
    backgroundColor: 'rgb(74, 144, 226)', // Bootstrap primary color
    borderRadius: 4, // Bootstrap border radius (.25rem)
  },
  sortButtonText: {
    color: '#fffff', // White color
    textAlign: 'center',
  },
  dateHeader: {
    marginTop: 16, // Bootstrap margin top (1rem)
    marginBottom: 8, // Bootstrap margin bottom (.5rem)
  },
  dateHeaderText: {
    fontSize: 20, // Bootstrap font size (1.25rem)
    fontWeight: 'bold', // Bootstrap font weight
    color: '#000', // added color
  },
  headerName: {
    padding: 11, // Bootstrap padding (.625rem)
    fontSize: 16, // Bootstrap font size (1rem)
    color: '#000', // added color
  },
  heroSection: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    padding: 15,
    borderRadius: 10,
    shadowColor: '#000',
    maxWidth:400,
    shadowOpacity: 0.1,
    shadowRadius: 10,
    shadowOffset: { width: 0, height: 5 },
    elevation: 5,
    marginBottom: 40,
  },
  textContainer: {
    flex: 1,
    alignItems: 'center',
    paddingRight: 20,
  },
  mainHeader: {
    fontSize: 28,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'left',
  },
  subHeader: {
    fontSize: 16,
    textAlign: 'left',
    color: '#555',
    marginBottom: 20,
  },
  joinButton: {
    backgroundColor: 'rgb(74, 144, 226)',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    marginBottom: 20,
  },
  joinButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  buttonRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 30,
  },
  startButton: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#4A90E2',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    marginHorizontal: 10,
  },
  buttonIcon: {
    width: 20,
    height: 20,
    marginRight: 10,
  },
  startButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  heroImage: {
    width: 200,
    height: 200,
    resizeMode: 'contain',
  },
  heroImageMobile: {
    maxWidth: '100%',
    height: 200,
    resizeMode: 'contain',
    marginBottom: 30,
  },
  section: {
    width: '100%',
    alignItems: 'center',
    marginBottom: 30,
    maxWidth:400,
    backgroundColor:'white',
    borderRadius:10,
    paddingTop:10,
  },
  sectionTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'center',
  },
  sectionDescription: {
    fontSize: 16,
    textAlign: 'left',
    color: '#555',
    paddingHorizontal: 20,
  },
  featureContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginBottom: 30,
  },
  featureBox: {
    width: '40%',
    backgroundColor: '#fff',
    padding: 15,
    margin: 10,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 10,
    shadowOffset: { width: 0, height: 5 },
    elevation: 5,
    alignItems: 'center',
  },
  featureIcon: {
    width: 30,
    height: 30,
    marginBottom: 10,
  },
  featureHeader: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#4A90E2',
    marginBottom: 5,
    textAlign: 'center',
  },
  featureDescription: {
    fontSize: 14,
    color: '#555',
    textAlign: 'left',
  },
  containerHeaderContainer: {

    borderColor: 'rgb(217, 217, 217)',
    borderWidth: 2,
    textAlign: 'center', // Added textAlign
    color: '#000', // added color
    borderRadius: 5,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 10,
    shadowOffset: { width: 0, height: 5 },
    backgroundColor: 'white',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  headerNameContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
  },
  leftArrow: {
    borderRadius: 5,
    backgroundColor: 'rgb(74, 144, 226)',
  },
  leftArrowText: {
    fontSize: 25,
    paddingHorizontal: 5,
    color: '#fff', // added color
    alignItems: 'center',
    paddingVertical: 5,
  },
  placeholder: {
    width: 35,
  },
  arrowButton: {
    alignSelf: 'center',
    backgroundColor: 'white',
    width: '100%',
    maxWidth: 400,
    backgroundColor: 'rgb(217, 217, 217)',
    shadowColor: '#000',
    shadowRadius: 10,
    shadowOffset: { width: 0, height: 5 },
    borderColor: 'rgb(217, 217, 217)',
    borderWidth: 2,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  arrowButtonText: {
    alignSelf: 'center',
    maxWidth: 400,
    width: '100%',
    textAlign: 'center',
  },
  headerButtonsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  backButton: {},
  headerBox: {
    borderWidth: 1,
    backgroundColor: '#fff',
    borderColor: '#ccc',
    borderRadius: 5,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 10,
    shadowOffset: { width: 0, height: 2 },
    borderColor: 'rgb(217, 217, 217)',
    borderWidth: 2,
    marginBottom: 15,
    maxWidth: 400,
    maxWidth: '100%',
  },
  backIcon: {
    fontSize: 20,
    color: '#000',
  },
  addTaskButton: {
    width: '100%',
    alignItems: 'center',
    padding: 10,
    backgroundColor: 'rgb(74, 144, 226)',
    borderRadius: 5,
  },
  buttonText: {
    color: '#fff',
    fontWeight: 'bold',
  },
  containerTop: {
    alignItems: 'center',
    width: '100%',
  },
  containerBottom: {
    flex: 1,
    alignItems: 'center',
    maxWidth: 400,
  },
  newTaskPadButton: {
    backgroundColor: 'rgb(74, 144, 226)',
    padding: 10, // Bootstrap padding (0.625rem)
    borderRadius: 5,
    marginBottom: 20, // Bootstrap margin bottom (1.25rem)
  },
  newTaskPadButtonText: {
    color: '#fff',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  toggleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 10, // Bootstrap margin vertical (0.625rem)
  },
  toggleLabel: {
    fontSize: 16, // Bootstrap font size (1rem)
    marginHorizontal: 10, // Bootstrap margin horizontal (0.625rem)
    color: '#000', // added color
  },
  toggleSwitch: {
    transform: [{ scaleX: 1.2 }, { scaleY: 1.2 }],
  },
  icon: {
    width: 25, // Bootstrap width (1.5625rem)
    height: 25, // Bootstrap height (1.5625rem)
    marginLeft: 15, // Bootstrap margin left (0.9375rem)
    marginRight: 15, // Bootstrap margin right (0.9375rem)
  },
  headerEditContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: 400,
  },
  editActions: {
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
  },
  headerEditInput: {
    width: '100%',
    maxWidth: 400,
    backgroundColor: 'rgb(240, 248, 255)',
    borderColor: 'rgb(74, 144, 226)',
    borderWidth: 2,
    borderRadius: 5,
    padding: 10, // Bootstrap padding (0.625rem)
    textAlign: 'center',
    color: '#000', // added color
  },
  clipboardLink: {
    fontSize: 16, // Bootstrap font size (1rem)
    color: 'rgb(74, 144, 226)',
    textAlign: 'center',
    marginVertical: 10, // Bootstrap margin vertical (0.625rem)
    textDecorationLine: 'underline',
  },
  actionIcons: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    width: '100%',
    backgroundColor: 'white',
    padding: 4,
    borderRadius: 5,
    borderWidth: 2,
    maxWidth: 400,
    borderColor: 'rgb(217, 217, 217)',
  },
  modal: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalActions: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '80%', // Bootstrap width (80%)
    marginTop: 20, // Bootstrap margin top (1.25rem)
  },
});

export const webStyles = {
  background: {
    justifyContent: 'center',
    width: '100%',
    minHeight: 'calc(100vh - 96px)', // Adjusted minHeight to account for footer
    backgroundColor: '#f8f9fa', // Bootstrap's light background color (fallback)
    paddingHorizontal: 5, // Add padding to create horizontal gap
    backgroundImage: `url(${backgroundImage})`, // Set the background image
    backgroundRepeat: 'repeat', // Repeat the image
    backgroundSize: 'auto', // Keep the original size of the image
  },
};
