// PostDetailsPageStyles.js
import { StyleSheet, Platform } from 'react-native';
import backgroundImage from '../assets/background.png';

const StylesPost = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: '100%', // Ensure the container takes the full height
    flex: 1, // Allow the container to grow and shrink as needed
  },
  containerHeader: {
    width: '100%',
   
    maxWidth:  Platform.OS === 'web' ? 400 : '100%',
  },
  contentBox: {
    maxWidth: 400,
    width: '100%',
    flex: 1, // Allow the content box to grow and shrink as needed
  },
  droppableContainer: {
    width: '100%',
    minHeight: 400,
  },
  draggableItem: {
    backgroundColor: '#fff',
    borderWidth: 2,
    borderColor: '#bbb',
    borderRadius: 5,
    marginBottom: 10,
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'rgb(74, 144, 226)',
    padding: 8,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    width: '100%',
    maxWidth: 400,
  },
  taskName: {
    fontWeight: 'bold',
    color: '#fff',
    borderRadius: 5,
  },
  taskNameEditing: {
    fontWeight: 'bold',
    color: '#000',
    backgroundColor: '#f0f8ff',
    width: '100%',
    padding: 5,
    fontSize: 16, // Set font size to 16 to prevent zooming
  },
  taskDescription: {
    marginTop: 5,
    color: '#333',
    paddingLeft: 10,
    paddingRight: 0,
  },
  textArea: {
    minHeight: 40,
    textAlignVertical: 'top',
    paddingBottom: 0,
    backgroundColor: '#f0f8ff',
    width: '100%',
    fontSize: 16, // Set font size to 16 to prevent zooming
  },
  editPostButton: {
    marginTop: 10,
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
  },
  actionIcons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 10,
  },
  icon: {
    width: 30,
    height: 30,
    marginRight: 8,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    backgroundColor: 'white',
    borderRadius: 5,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 10,
    shadowOffset: { width: 0, height: 5 },
    borderColor: 'rgb(217, 217, 217)',
    borderWidth: 2,
    marginBottom: 10,
  },
  backIcon: {
    fontSize: 25,
    padding: 5,
    color: '#fff', // added color
    alignItems: 'center',
    borderRadius: 5,
    backgroundColor: 'rgb(74, 144, 226)',
  },
  headerTitle: {
 
    width: '100%',
    paddingRight: 34,
    backgroundColor: 'white',
    fontSize: 16, // Bootstrap font size (1rem)
    color: '#000', // added color
    textAlign: 'center', // Added textAlign
    color: '#000', // added color
  },
  placeholder: {},
  background: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    ...Platform.select({
      web: {
        minHeight: 'calc(100vh - 96px)', // Adjust minHeight for web to account for the footer
      },
      default: {
        minHeight: '100%',
      },
    }),
  },
});

export default StylesPost;

export const webStyles = {
  background: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    minHeight: 'calc(100vh - 120px)', // Adjusted minHeight to account for footer

    backgroundColor: '#f8f9fa', // Bootstrap's light background color (fallback)
    paddingHorizontal: 5, // Add padding to create horizontal gap
    backgroundImage: `url(${backgroundImage})`, // Set the background image
    backgroundRepeat: 'repeat', // Repeat the image
    backgroundSize: 'auto', // Keep the original size of the image
  },
};
